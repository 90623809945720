import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { useEffect, useRef, useState } from 'react';
import { useAuth } from './AuthProvider';
import { setContext } from '@apollo/client/link/context';
import { useApiHost } from '../../providers/ApiHostProvider';
import { createHttpLink } from 'apollo-link-http';
import { ApolloLink } from '@apollo/client/core';
//
// import { print } from 'graphql';
// import { createClient } from 'graphql-ws';
import { WebSocketLink } from '@apollo/client/link/ws';
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link';
import cleanTypenameLink from '../gql/apollo-links/cleanTypenameLink';
import typePoliciesQueryFields from '../gql/type-policies/typePoliciesQueryFields';
//
// class WebSocketLink extends ApolloLink {
//   constructor(options) {
//     super();
//     this.client = createClient(options);
//   }
//
//   request(operation) {
//     return new Observable(sink => {
//       return this.client.subscribe(
//         { ...operation, query: print(operation.query) },
//         {
//           next: sink.next.bind(sink),
//           complete: sink.complete.bind(sink),
//           error: sink.error.bind(sink),
//         }
//       );
//     });
//   }
// }

const ApolloClientProvider = ({
  endpointOverride,
  endpoint,
  children,
  allowSubscription = true,
  defaultEndpoint,
}) => {
  const clientRef = useRef(null);
  const { token } = useAuth();
  const { apiBaseUrl } = useApiHost();

  const [initialized, setInitialized] = useState(false);

  const defaultEndpointLink =
    typeof endpoint === 'string' ? endpoint : endpoint[defaultEndpoint];

  useEffect(() => {
    if (!apiBaseUrl) {
      return;
    }

    const httpLink =
      typeof endpoint === 'string'
        ? ApolloLink.from([
            cleanTypenameLink,
            new HttpLink({
              uri: `https://${apiBaseUrl}${endpoint}/`,
            }),
          ])
        : ApolloLink.from([
            cleanTypenameLink,
            new MultiAPILink({
              endpoints: Object.keys(endpoint).reduce((acc, key) => {
                return {
                  ...acc,
                  [key]: `https://${apiBaseUrl}${endpoint[key]}/`,
                };
              }, {}),
              createHttpLink: () => createHttpLink(),
              httpSuffix: '',
              defaultEndpoint,
            }),
          ]);

    // const wsLink = new WebSocketLink({
    //   url: endpointOverride
    //     ? endpointOverride
    //     : `wss://${apiBaseUrl}${endpoint}/`,
    //   connectionParams: () => {
    //     const session = getSession();
    //     if (!session) {
    //       return {};
    //     }
    //     return {
    //       Authorization: token ? `Bearer ${token}` : '',
    //     };
    //   },
    // });

    const wsLink = allowSubscription
      ? new WebSocketLink({
          uri: `wss://${apiBaseUrl}${defaultEndpointLink}/`,
          options: {
            reconnect: true,
            connectionParams: {
              headers: {
                Authorization: token ? `Bearer ${token}` : '',
              },
            },
          },
        })
      : null;

    const splitLink =
      wsLink &&
      split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        wsLink,
        httpLink
      );

    clientRef.current = new ApolloClient({
      link: setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
          },
        };
      }).concat(splitLink || httpLink),
      cache: new InMemoryCache({
        // addTypename: false,
        typePolicies: {
          Query: {
            fields: typePoliciesQueryFields,
          },
        },
      }),
    });

    setInitialized(true);
    // eslint-disable-next-line
  }, [
    token,
    apiBaseUrl,
    endpointOverride,
    allowSubscription,
    defaultEndpoint,
    defaultEndpointLink,
  ]);

  if (!initialized) {
    return null;
  }

  return <ApolloProvider client={clientRef.current}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
