import React, { forwardRef, useContext } from 'react';
import FieldContainer from '../FieldContainer';
import FieldActions from '../FieldActions';
import UiOptionsProvider from '../UiOptionsProvider';
import {
  compileFieldSchema,
  compileSchema,
  ComponentEnum,
  prepareFieldSchema,
} from '../FieldUtils';
import FieldValueContainer from '../FieldValueContainer';
import FieldDebugger from '../FieldDebugger';
import { DebugModeContext } from '../../../providers/DebugModeProvider';
import { LinksContext } from '../../../providers/LinksProvider';
import { RoutesContext } from '../../../providers/RoutesProvider';
import { useParams } from 'react-router-dom';
import { NestedIndexesContext } from '../../../providers/NestedIndexProvider';
import { DataContext } from '../../../providers/DataProvider';
import EmptyFieldPlaceholder from '../EmptyFieldPlaceholder';
import { getTo } from '../../../utils/schemaHelper';
import { getByIndexes } from '../../Table/TableUtils';
import DecoratorsProvider from '../DecoratorsProvider';
import VerticalStripDecorator from '../VerticalStripDecorator';

const Field = forwardRef(
  (
    {
      align = 'left',
      fieldKey,
      fieldValue,
      schema,
      uiSchema,
      onClick = () => {},
      classes = {},
      labeled,
    },
    ref
  ) => {
    const { data, query: requestQuery } = useContext(DataContext);
    const indexes = useContext(NestedIndexesContext);
    const debugEnabled = useContext(DebugModeContext);
    const compiledUiSchema = uiSchema && compileSchema(uiSchema, data, indexes);

    const fieldSchema = prepareFieldSchema(
      fieldKey,
      fieldValue,
      compiledUiSchema,
      schema
    );

    const compiledSchema = compileFieldSchema(fieldSchema, data, indexes);

    const links = useContext(LinksContext);
    const routes = useContext(RoutesContext);
    const requestPath = useParams();

    // Todo remove
    if (compiledSchema.value.link) {
      if (compiledSchema.value.link.operationId) {
        const link = links[compiledSchema.value.link.operationId];

        const linkParameters = compiledSchema.value.link.parameters || {};

        compiledSchema.value.link =
          link &&
          getTo({
            link: {
              ...link,
              parameters: {
                ...link.parameters,
                ...linkParameters,
              },
            },
            routes,
            requestQuery: requestQuery,
            requestPath: requestPath,
            responseBody: getByIndexes(data, indexes),
          });

        // compiledSchema.value.linkPath = getPathByOperationId(schema, compiledSchema.value.link.operationId);
      }
    }

    const { decorators = {} } = compiledSchema;

    const nullable =
      compiledSchema.value.component === ComponentEnum.BOOLEAN_GROUP_INPUT ||
      compiledSchema.value.component === ComponentEnum.WATCH;

    const Field = (
      <DecoratorsProvider value={decorators}>
        <FieldContainer
          onClick={onClick}
          align={align}
          className={classes.container}
        >
          {decorators.verticalStrip && (
            <VerticalStripDecorator color={decorators.verticalStrip.color} />
          )}
          {nullable ||
          (typeof fieldValue !== 'undefined' && fieldValue !== null) ? (
            <FieldValueContainer />
          ) : (
            <EmptyFieldPlaceholder />
          )}
          <FieldActions />
        </FieldContainer>
      </DecoratorsProvider>
    );

    return (
      <div ref={ref}>
        <UiOptionsProvider value={compiledSchema}>
          {debugEnabled ? (
            <>
              <FieldDebugger
                compiledSchema={compiledSchema}
                uiSchema={uiSchema}
                schema={schema}
              >
                {Field}
              </FieldDebugger>
            </>
          ) : (
            Field
          )}
        </UiOptionsProvider>
      </div>
    );
  }
);

export default Field;
