import * as yup from 'yup';
import dayjs from 'dayjs';
import { EVENT_STATUSES } from '../../../constants';

const TradingEventValidationSchema = yup.object({
  sport: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required()
    .default(null),
  category: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required()
    .default(null),
  tournament: yup
    .object({
      id: yup.string(),
      name: yup.string(),
    })
    .required()
    .default(null),
  scheduled: yup
    .string()
    .required()
    .default(null)
    .when('status', {
      is: EVENT_STATUSES.prematch,
      then: schema =>
        schema.test(
          'isGreaterThanCurrent',
          'Must be greater than current date and time (utc).',
          value => dayjs(value).unix() >= dayjs().unix()
        ),
    }),
  status: yup.string().required().default(EVENT_STATUSES.draft),
  competitors: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.string(),
        name: yup.string().required(),
      })
    )
    .default([null, null]),
  score: yup
    .object({
      home: yup.number(),
      away: yup.number(),
    })
    .default({
      home: 0,
      away: 0,
    }),
  markets: yup
    .object()
    .shape({
      [yup.string()]: yup.object().shape({
        id: yup.string(),
        name: yup.string(),
        enabled: yup.boolean(),
        outcomes: yup.object(),
      }),
    })
    .default({}),
  createSpecifierValues: yup.object().default({}),
});

export default TradingEventValidationSchema;
