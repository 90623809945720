import { identity, isEmpty, pickBy } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTreeSelectContext } from '../../../../../../providers/TreeSelectProvider';
import { withoutEmpty } from '../../../../../hooks/usePresetFilters';
import {
  LINE_TYPES,
  SELECT_NODE_TYPES,
  TEMPLATE_CONFIG_NODES,
} from '../../../../../pages/LineSettingsPage/FormPages/Template/components/TemplateConfigurationField';
import { useToasts } from '../../../../../providers/ToastsProvider';
import NotificationAlert, {
  AlertVariant,
} from '../../../../Notifications/NotificationAlert';
import Loader from '../../components/Loader';
import TreeSelectContainer from '../../components/TreeSelectContainer';
import SourcesCategorySelect from './Selects/SourcesCategorySelect';
import SourcesEventSelect from './Selects/SourcesEventSelect';
import SourcesMarketSelect from './Selects/SourcesMarketSelect';
import SourcesSportSelect from './Selects/SourcesSportSelect';
import SourcesTournamentSelect from './Selects/SourcesTournamentSelect';

export const MarketProviderNames = {
  dst: 'Dst',
  cns: 'Cns',
  betgenius: 'Betgenius',
  oddin: 'Oddin',
  betradar_wac: 'Betradar_Wac',
  betradar_tanktech: 'Betradar_Tanktech',
  betradar_herogaming: 'Betradar_Herogaming',
  betradar: 'Betradar',
  betradar_replay: 'Betradar_replay',
  lsport: 'Lsport',
  betby_trading: 'Betby_trading',
  tradeart: 'Tradeart',
  pandascore: 'Pandascore',
};

const SourcesTreeSelect = ({ templateId, lineType }) => {
  const selectNames = [
    TEMPLATE_CONFIG_NODES.SPORT,
    TEMPLATE_CONFIG_NODES.CATEGORY,
    TEMPLATE_CONFIG_NODES.TOURNAMENT,
    TEMPLATE_CONFIG_NODES.EVENT,
    TEMPLATE_CONFIG_NODES.MARKET,
  ];

  const selectComponents = {
    [TEMPLATE_CONFIG_NODES.SPORT]: SourcesSportSelect,
    [TEMPLATE_CONFIG_NODES.CATEGORY]: SourcesCategorySelect,
    [TEMPLATE_CONFIG_NODES.TOURNAMENT]: SourcesTournamentSelect,
    [TEMPLATE_CONFIG_NODES.EVENT]: SourcesEventSelect,
    [TEMPLATE_CONFIG_NODES.MARKET]: SourcesMarketSelect,
  };

  const { getValues } = useFormContext();
  const { showToast } = useToasts();

  const switchedFrom = getValues('switchedFrom');

  const {
    nodePath: { [lineType]: nodePath },
    setNodePath,
    preset,
    data: { [lineType]: data },
    setData,
    treeSelectLoading,
    setTreeSelectLoading,
    getSourcesTemplateQuery,
    setTreeSelectCrashed,
  } = useTreeSelectContext();

  const variables = useMemo(() => {
    return nodePath.sportId
      ? {
          templateId,
          lineType,
          path: pickBy(
            {
              sportId: nodePath.sportId,
              categoryId: nodePath.categoryId,
              tournamentId: nodePath.tournamentId,
              eventId: nodePath.eventId,
            },
            identity
          ),
        }
      : { templateId, lineType };
  }, [nodePath, lineType, templateId]);

  useEffect(() => {
    isEmpty(data) &&
      getSourcesTemplateQuery(variables)
        .then(data => {
          const newData = withoutEmpty(data.data.data);
          setTreeSelectLoading(false);

          setData(currentData => ({
            ...currentData,
            [lineType]: { ...newData },
          }));
        })
        .catch(() => {
          //if we get error while initially load select we need to undo template type select
          setTreeSelectLoading(false);
          setTreeSelectCrashed(true);
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR} timeout={5000}>
              Can not fetch data
            </NotificationAlert>
          );
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <TreeSelectContainer>
      {treeSelectLoading && <Loader onTopOfContent />}
      {selectNames.map((value, index) => {
        if (data[value] && !isEmpty(nodePath)) {
          const selectOptions = data[value];
          const TreeSelectElement = selectComponents[value];
          const selectPreset = preset.selectType === value ? preset : {};

          //this used to change key only after changes data for this menu
          const selectOptionsJson = JSON.stringify(selectOptions);

          //markets have to change key also every time when changed nodePath
          const uniqKey =
            (value === TEMPLATE_CONFIG_NODES.MARKET
              ? selectOptionsJson + JSON.stringify(nodePath)
              : selectOptionsJson) + JSON.stringify(selectPreset);

          return (
            <TreeSelectElement
              key={uniqKey}
              templateId={templateId}
              options={selectOptions}
              nodePath={nodePath}
              selectKey={value}
              selectNodeType={SELECT_NODE_TYPES[value]}
              nodeIndex={index}
              marketType={data.marketsToNode}
            />
          );
        }
      })}
    </TreeSelectContainer>
  );
};

export default SourcesTreeSelect;
