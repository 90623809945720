import { getActiveSchemaUrl } from '../reducers/schemaUrls';
import { createAction } from 'redux-actions';
import {
  getUser,
  getUserRefreshToken,
  getUserRefreshUrl,
} from '../reducers/users';
import { getPathByOperationId, toEndpoint } from '../utils/schemaHelper';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const loginSuccess = createAction('LOGIN_SUCCESS');

export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const login =
  ({ payload: { data } }, schemaState = {}) =>
  (dispatch, getState) => {
    const { apiHost, routes } = schemaState;
    const state = getState();
    const schemaUrl = getActiveSchemaUrl(state);
    const refreshPath = routes && getPathByOperationId(routes, 'Auth.refresh');

    const refreshEndpoint = toEndpoint({ path: refreshPath });

    if (data && data.token) {
      const user = {
        ...data.user,
        token: data.token,
        refresh_token: data.refresh_token,
        refresh_url: `${apiHost}${refreshEndpoint}`,
      };
      dispatch(loginSuccess({ user, schemaUrl }));
    }
    // fetchSchema(activeSchemaUrl, data.token);
  };

export const logout = () => (dispatch, getState) => {
  const schemaUrl = getActiveSchemaUrl(getState());
  dispatch(logoutSuccess(schemaUrl));
  // fetchSchema(schemaUrl);
};

export const refreshToken = () => (dispatch, getState) => {
  const state = getState();

  const userRefreshTokenUrl = getUserRefreshUrl(state);
  const refreshToken = getUserRefreshToken(state);
  const user = getUser(state);
  const schemaUrl = getActiveSchemaUrl(state);

  if (userRefreshTokenUrl) {
    fetch(userRefreshTokenUrl, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        refresh_token: refreshToken,
      }),
    })
      .then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then(async response => {
        dispatch(
          loginSuccess({
            user: {
              ...user,
              ...response,
            },
            schemaUrl,
          })
        );
      })
      .catch(error => {
        dispatch(logout());
      });
  }
};
