import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef, useCallback, useRef } from 'react';
import ExpandIcon from '../../../icons/controls/ExpandIcon';
import mergeRefs from '../../../utils/mergeRefs';

const useStyles = makeStyles({
  numberField: {},

  numberFieldInput: {
    height: 40,
    boxSizing: 'border-box',
    appearance: 'textfield',

    '&::-webkit-inner-spin-button': {
      appearance: 'none',
    },
    '&::-webkit-inner-outer-button': {
      appearance: 'none',
    },
  },

  numberFieldInputLabel: {
    paddingRight: 8,
  },

  numberInputControls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 36,
    color: '#1581FF',

    '& svg': {
      cursor: 'pointer',
    },
  },
});

const NumberField = forwardRef(
  (
    {
      min,
      max,
      step,
      onChange,
      onBlur,
      value,
      fullWidth = true,
      required,
      error,
      helperText,
      disabled,
      useStringValue = false,
      ...props
    },
    ref
  ) => {
    const classes = useStyles();

    const inputRef = useRef(null);

    const handleChange = useCallback(
      value => {
        console.log(value);
        if (!onChange) {
          return;
        }

        if (typeof min !== 'undefined' && value && value < min) {
          onChange(useStringValue ? min.toString() : min);
        } else if (typeof max !== 'undefined' && value && value > max) {
          onChange(useStringValue ? max.toString() : max);
        } else {
          onChange(
            useStringValue
              ? value.toString()
              : typeof Number(value) === 'number'
              ? value === ''
                ? ''
                : Number(value)
              : value
          );
        }
      },
      [onChange, min, max, useStringValue]
    );

    const handleStepUp = useCallback(() => {
      inputRef.current.stepUp();
      handleChange(inputRef.current.value);
    }, [inputRef, handleChange]);

    const handleStepDown = useCallback(() => {
      inputRef.current.stepDown();
      handleChange(inputRef.current.value);
    }, [inputRef, handleChange]);

    return (
      <TextField
        inputProps={{
          min,
          max,
          step,
          ref: mergeRefs(ref, inputRef),
        }}
        classes={{
          root: classes.numberField,
        }}
        InputLabelProps={{
          style: {
            overflow: 'hidden',
          },
          classes: {
            root: step && classes.numberFieldInputLabel,
          },
        }}
        InputProps={{
          classes: {
            input: classes.numberFieldInput,
          },
          endAdornment: step && !disabled && (
            <div className={classes.numberInputControls}>
              <ExpandIcon
                expanded
                onClick={handleStepUp}
                disabled={step && max && value + step > max}
              />
              <ExpandIcon
                onClick={handleStepDown}
                disabled={step && min && value - step < min}
              />
            </div>
          ),
        }}
        value={value === null || typeof value === 'undefined' ? '' : value}
        type={'number'}
        margin={'dense'}
        variant="outlined"
        fullWidth={fullWidth}
        required={required}
        error={error}
        helperText={helperText}
        disabled={disabled}
        {...props}
        onChange={e => handleChange(e.target.value)}
        onBlur={onBlur}
      />
    );
  }
);

export default NumberField;
