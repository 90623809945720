import { gql } from '@apollo/client';

const getEventDetails = gql`
  query getEventDetails($eventId: EventId!) {
    data: getEventDetails(eventId: $eventId) {
      id
      event
      eventScheduled
      providers {
        id
        name
      }
      category {
        id
        name
      }
      tournament {
        id
        name
      }
      sport {
        id
        name
      }
      ggr
      avgBet
      maxBet
      players
      bets
      turnover
      turnoverOpen
      lastBetDate
      lastBetDateBet
      firstBetDate
      firstBetDateBet
      offered
      maxGgrEur
      minGgrEur
      freebetBets
      freebetPlayers
      freebetTurnover
      freebetGgr
    }
  }
`;

export default getEventDetails;
