import Select from '../Select/Select';
import { forwardRef } from 'react';
import useAutocompleteData from '../../../hooks/useAutocompleteData';

const LocaleCountrySelect = forwardRef(
  ({ multiple, lang, disabled, ...props }, ref) => {
    const { data: options = [] } = useAutocompleteData({
      url: `/api/v1/Autocomplete/lang_subtag?lang=${lang}`,
      withoutParams: true,
      skip: !lang,
      disabled,
    });

    return (
      <Select
        ref={ref}
        label={'Country (All Countries)'}
        multiple={multiple}
        options={options}
        labelKey={'name'}
        valueKey={'id'}
        disabled={disabled}
        {...props}
      />
    );
  }
);

export default LocaleCountrySelect;
