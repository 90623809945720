import { gql } from '@apollo/client';

const getSportMarkets = gql`
  query getSportMarkets($sportId: SportId!, $name: String) {
    getSportMarkets(sport_id: $sportId, name: $name) {
      id
      name
      enabled
    }
  }
`;

export default getSportMarkets;
