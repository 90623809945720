import React, { useMemo } from 'react';
import TradingEventEditForm from './components/TradingEventEditForm/TradingEventEditForm';
import TradingEventValidationSchema from './components/TradingEventEditForm/TradingEventValidationSchema';
import EVENT_QUERY from '../../../gql/TradingTool/queries/EVENT_QUERY';
import useImperativeQuery from '../../../hooks/useImperativeQuery';

// these manipulations below are needed to alter specifier name so that react-hook-form can use it without bugging out
// replace possible empty specifier names '' to 'specifier_', because '' will be ignored by react-hook-form fields
// also replacing dots on specifiers, like hcp=0.5 to hcp=0__5, because dots in name will create new objects in react-hook-form state
// also replace '|' divider in combined specifiers to '---' because it causes react-hook-form to crush on register (i like this library)
export const prefixSpecifierName = specifier => {
  const individualSpecifiers = specifier.split('|');

  return individualSpecifiers
    .map(specifier => `specifier_${specifier.replace('.', '__')}`)
    .join('---');
};
export const unprefixSpecifierName = specifier => {
  const individualSpecifiers = specifier.split('---');

  return individualSpecifiers
    .map(specifier => specifier.slice(10).replace('__', '.'))
    .join('|');
};

const TradingEventEditContainer = ({
  activeTab,
  setActiveTab,
  eventId,
  eventData,
  creationFlowInitialData,
  onBackButtonClick,
  refetchEvents,
}) => {
  const { fetch, error } = useImperativeQuery(EVENT_QUERY, {
    fetchPolicy: 'no-cache',
  });

  const getInitialValues = useMemo(() => {
    const defaultValues = TradingEventValidationSchema.default();

    return async () => {
      return new Promise(resolve => {
        if (!eventId) {
          resolve({ ...defaultValues, ...creationFlowInitialData });
        } else {
          fetch({
            id: eventId,
          }).then(({ data }) => {
            if (data?.data) {
              const {
                fixture: {
                  sport,
                  category,
                  tournament,
                  scheduled,
                  competitors,
                },
                stats,
                markets,
              } = data.data;

              const { status } = data.data.state;

              resolve({
                sport,
                category,
                tournament,
                scheduled,
                competitors,
                status,
                score: stats.length ? stats[0].score : defaultValues.score,
                markets: markets.length
                  ? markets.reduce((acc, market) => {
                      const outcomes = Object.keys(market.outcomes).reduce(
                        (acc, specifier) => {
                          acc[prefixSpecifierName(specifier)] =
                            market.outcomes[specifier];
                          return acc;
                        },
                        {}
                      );

                      acc[market.id] = {
                        ...market,
                        outcomes,
                      };

                      return acc;
                    }, {})
                  : defaultValues.markets,
                createSpecifierValues: {},
              });
            } else {
              resolve(defaultValues);
            }
          });
        }
      });
    };
  }, [fetch, eventId, creationFlowInitialData]);

  return (
    <>
      {error ? (
        <div>Something went wrong while loading event details</div>
      ) : (
        <TradingEventEditForm
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isCreating={!eventId}
          defaultValues={getInitialValues}
          eventId={eventId}
          eventData={eventData}
          onBackButtonClick={onBackButtonClick}
          refetchEvents={refetchEvents}
        />
      )}
    </>
  );
};

export default TradingEventEditContainer;
