import { ActionCardTypeEnum } from '../../components/ActionCards';
import TableFiltersActionCard from '../components/TableActionCards/TableFiltersActionCard';
import Filters from '../components/Filters/Filters';
import { useCallback, useMemo, useState } from 'react';
import useLocalStorageTableConfig, {
  tableConfigKeys,
  tableNames,
} from './useLocalStorageTableConfig';
import { useApplyRequired } from '../providers/ApplyRequiredProvider';

const useFiltersActionCard = ({
  filters,
  setFilters,
  defaultValues,
  filtersQuery,
  onSubmit,
  onResubmit,
  spanMin = 2,
  spanMax = 4,
  hidePresets,
  extraFilters,
  isDefaultPreset = true,
  tableName = tableNames.GenericTable,
  onChangePresetFilters,
}) => {
  const { setApplyRequired } = useApplyRequired(tableName);
  const { getTableConfigValue, setTableConfigValue } =
    useLocalStorageTableConfig(tableName);
  const configIsOpenedValue = getTableConfigValue(
    tableConfigKeys.isFiltersOpened
  );
  const [isOpen, setIsOpen] = useState(configIsOpenedValue);

  const onSubmitCb = useCallback(
    data => {
      setApplyRequired(false);
      if (JSON.stringify(data) === JSON.stringify(filtersQuery)) {
        onResubmit();
      } else {
        onSubmit(data);
      }
    },
    [onSubmit, onResubmit, filtersQuery, setApplyRequired]
  );

  return useMemo(
    () => ({
      type: ActionCardTypeEnum.FILTERS,
      key: ActionCardTypeEnum.FILTERS,
      span: isOpen ? spanMax : spanMin,
      //filters form work with react-hook-form
      //if filter have a deps property you must pass component prop in filter object as callback
      //if no deps component prop must be jsx tag
      render: (style, onChangeHeight, inline, large) => (
        <TableFiltersActionCard
          style={style}
          isOpen={isOpen}
          onChange={expanded => {
            setIsOpen(expanded);
            setTableConfigValue(tableConfigKeys.isFiltersOpened, expanded);
            // setUserStorage({
            //   ...userStorage,
            //   isFiltersExpanded: expanded,
            // });
          }}
          onChangeHeight={onChangeHeight}
          inline={inline}
          large={large}
        >
          <Filters
            hidePresets={hidePresets}
            initialValues={filtersQuery}
            defaultValues={defaultValues}
            filters={filters}
            onToggle={setFilters}
            onSubmit={onSubmitCb}
            extraFilters={extraFilters}
            isDefaultPreset={isDefaultPreset}
            onChangePresetFilters={onChangePresetFilters}
          />
        </TableFiltersActionCard>
      ),
    }),
    [
      defaultValues,
      filters,
      filtersQuery,
      isOpen,
      onSubmitCb,
      setFilters,
      spanMin,
      spanMax,
      hidePresets,
      extraFilters,
      isDefaultPreset,
      setTableConfigValue,
      onChangePresetFilters,
    ]
  );
};

export default useFiltersActionCard;
