import { useDispatch, useSelector } from 'react-redux';
import { toggleDrawer } from '../actions/uiActions';
import { getGlobalNotification } from '../reducers/notification';
import { getActiveSchemaUrl, getSchemaUrls } from '../reducers/schemaUrls';
import React, { useCallback, useMemo } from 'react';
import { compose } from 'recompose';
import Root from '../components/Root';
import { ADD_SCHEMA_URL } from '../constants/appConstants';
import { useRoutes } from '../providers/RoutesProvider';
import { getUser } from '../reducers/users';
import LoginPage from '../components/LoginPage';
import Fade from '@mui/material/Fade';
import MessageBar from '../components/MessageBar';
import isEmpty from 'lodash/isEmpty';
import { useRawSchema } from '../providers/RawSchemaProvider';
import GlobalLoading from '../components/GlobalLoading';
import withRouter from '../utils/withRouter';
import { matchRoutes, useLocation } from 'react-router-dom';
import v2routes from '../v2/routes';

const withProps = BaseComponent => {
  return props => {
    const activeSchemaUrl = useSelector(getActiveSchemaUrl);
    const schemaUrls = useSelector(getSchemaUrls);
    const routes = useRoutes();
    const notification = useSelector(getGlobalNotification);
    const dispatch = useDispatch();

    const location = useLocation();
    const isV2Route = useMemo(
      () => matchRoutes(v2routes, location),
      [location]
    );

    const toggleDrawerCb = useCallback(
      () => dispatch(toggleDrawer()),
      [dispatch]
    );

    const addSchemaUrlCb = useCallback(
      schemaUrl =>
        dispatch({
          type: ADD_SCHEMA_URL,
          meta: {
            schemaUrl,
          },
        }),
      [dispatch]
    );

    const { loading: schemaLoading } = useRawSchema();
    const user = useSelector(getUser);

    const loggedIn = user && (!isEmpty(routes) || isV2Route);

    const loading = schemaLoading && !isV2Route;

    return (
      <>
        {loading && <GlobalLoading />}
        <Fade
          in={!schemaLoading && !loggedIn}
          unmountOnExit={true}
          timeout={600}
          exit={false}
        >
          <LoginPage />
        </Fade>
        {!loading && loggedIn && (
          <BaseComponent
            activeSchemaUrl={activeSchemaUrl}
            schemaUrls={schemaUrls}
            routes={routes}
            addSchemaUrl={addSchemaUrlCb}
            toggleDrawer={toggleDrawerCb}
            {...props}
          />
        )}

        {notification && <MessageBar notification={notification} />}
      </>
    );
  };
};

const RootContainer = compose(withRouter, withProps)(Root);

export default RootContainer;
