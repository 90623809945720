import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

const ApplyRequiredGlobalContext = createContext({
  values: {},
  setValues: () => {},
});

const ApplyRequiredContext = createContext({
  isApplyRequired: false,
  setApplyRequired: () => {},
});

export const useApplyRequired = () => {
  return useContext(ApplyRequiredContext);
};

export const ApplyRequiredGlobalProvider = ({ children }) => {
  const [values, setValues] = useState({});

  const providerValue = useMemo(() => {
    return {
      values,
      setValues,
    };
  }, [values, setValues]);

  return (
    <ApplyRequiredGlobalContext.Provider value={providerValue}>
      {children}
    </ApplyRequiredGlobalContext.Provider>
  );
};

/**
 Wrap table(or component/page with table) in which you want to use useApplyRequired hook with this provider.
 It will set "applyRequired" state to "true" on mount.
 */
const ApplyRequiredProvider = ({ tableName, children }) => {
  const { values, setValues } = useContext(ApplyRequiredGlobalContext);

  useEffect(() => {
    setValues({ ...values, [tableName]: true });

    return () => {
      setValues({ ...values, [tableName]: false });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableName]);

  const isApplyRequired = useMemo(() => {
    return !!values[tableName];
  }, [tableName, values]);

  const setApplyRequired = useCallback(
    value => {
      setValues({ ...values, [tableName]: value });
    },
    [tableName, values, setValues]
  );

  const providerValue = useMemo(() => {
    return {
      isApplyRequired,
      setApplyRequired,
    };
  }, [isApplyRequired, setApplyRequired]);

  return (
    <ApplyRequiredContext.Provider value={providerValue}>
      {children}
    </ApplyRequiredContext.Provider>
  );
};

export default ApplyRequiredProvider;
