import AddButton from '../Button/AddButton';
import { useCallback, useState } from 'react';
import { Menu } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import CheckBoxIcon from '../../../icons/controls/CheckBoxIcon';
import CheckBoxActiveIcon from '../../../icons/controls/CheckBoxActiveIcon';
import Checkbox from '@mui/material/Checkbox';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles({
  menuItem: {
    height: 40,
  },
});

const FiltersMenu = ({ filters = [], onToggle = () => {} }) => {
  const classes = useStyles();

  const [toggleState, setToggleState] = useState(
    filters.reduce((acc, filter) => {
      return {
        ...acc,
        [filter.name]: filter.toggled,
      };
    }, {})
  );

  const onToggleItem = useCallback(name => {
    setToggleState(toggleState => ({
      ...toggleState,
      [name]: !toggleState[name],
    }));
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    onToggle(
      filters.map(filter => {
        return {
          ...filter,
          toggled: toggleState[filter.name],
        };
      })
    );
  };

  return (
    <div>
      <AddButton
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Add more filters
      </AddButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            width: 304,
            borderRadius: 8,
            boxShadow:
              '0px 8px 32px rgba(132, 150, 171, 0.16), 0px 2px 16px rgba(132, 150, 171, 0.25)',
          },
        }}
      >
        {filters.map(filter => {
          return (
            !filter.hidden && (
              <MenuItem
                key={filter.name}
                disableRipple
                className={classes.menuItem}
                onClick={() => {
                  onToggleItem(filter.name);
                }}
              >
                <ListItemText primary={filter.label} />
                <Checkbox
                  icon={<CheckBoxIcon />}
                  checkedIcon={<CheckBoxActiveIcon />}
                  checked={toggleState[filter.name]}
                />
              </MenuItem>
            )
          );
        })}
      </Menu>
    </div>
  );
};

export default FiltersMenu;
