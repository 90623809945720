import Box from '@mui/material/Box';
import { forwardRef, useEffect, useRef } from 'react';
import mergeRefs from '../../../utils/mergeRefs';

const removeScroll = wrapper => {
  wrapper.style.overflow = null;
  const scrollRoot = wrapper.parentNode.parentNode.parentNode.parentNode;
  const horizontalScroll = scrollRoot.children[1];
  horizontalScroll.style.visibility = 'hidden';
};

const returnScroll = wrapper => {
  wrapper.style.overflow = 'scroll hidden';
  const scrollRoot = wrapper.parentNode.parentNode.parentNode.parentNode;
  const horizontalScroll = scrollRoot.children[1];
  horizontalScroll.style.visibility = 'visible';
};

const TableBox = forwardRef(
  ({ scrollable, containerWidth, scrollbar, children }, ref) => {
    const innerRef = useRef(null);

    useEffect(() => {
      if (scrollbar) {
        innerRef.current.style.width === 'max-content'
          ? removeScroll(scrollbar)
          : returnScroll(scrollbar);
      }
      if (scrollable && innerRef) {
        innerRef.current.style.width = 'max-content';
        const fixedWidth = innerRef.current.getBoundingClientRect().width;
        const parentWidth =
          innerRef.current.parentNode.getBoundingClientRect().width;
        if (fixedWidth > parentWidth) {
          innerRef.current.style.width = `${fixedWidth}px`;
        }
      }
    }, [innerRef, ref, children, scrollable, scrollbar]);

    return (
      <Box
        ref={mergeRefs(ref, innerRef)}
        sx={{
          width: scrollable ? 'max-content' : '100%',
          minWidth: scrollable && '100%',
          marginBottom: scrollable && '10px',
          '& .tableHeaderRow': {
            display: 'grid',
            alignItems: 'center',
            padding: '2px',
            paddingBottom: '14px',
            paddingTop: '14px',
            position: 'sticky',
            // top: '48px',
            zIndex: 1,
            backgroundColor: '#F7F7F7',
            width: '100%',
            '&::after': {
              pointerEvents: 'none',
              boxShadow: '0px 2px 4px rgba(132, 150, 171, 0)',
              content: '""',
              width: 'calc(100% + 24px)',
              left: -16,
              height: 44,
              position: 'absolute',
              transition: 'box-shadow 0.2s ease-in',
            },
          },

          '& .tableStickyHeader': !scrollable && {
            position: 'sticky',
            top: '48px',
            '&::after': {
              boxShadow: '0px 2px 4px rgba(132, 150, 171, 0.4)',
              width: 'calc(100% + 32px)',
              height: 43,
            },
          },
          '& .columnLabel': {
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            color: '#031224',
            fontWeight: 600,
            fontSize: '11px',
            lineHeight: '13px',
            letterSpacing: '0.2px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
          '& .tableHeaderColumn': {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
            boxSizing: 'border-box',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            position: 'relative',
          },
          '& .tableSortableColumn': {
            cursor: 'pointer',
            '&:hover': {
              color: '#1581FF',
            },
          },

          '& .tableHeaderColumnResizable': {
            '&::after': {
              content: '""',
              position: 'absolute',
              height: '24px',
              width: '1px',
              backgroundColor: '#8496AB',
              borderRadius: '0.5px',
              right: '0px',

              ':hover': {
                backgroundColor: '#1581FF',
              },
            },
          },
          '& .tableHeaderColumnLast': {
            flexGrow: 1,
            '&::after': {
              display: 'none',
            },
          },

          '& .tableRow': {
            display: 'flex',
            flexDirection: 'column',
            height: 'fit-content',
          },

          '& .tableRow .tableRowContent:before': {
            content: '""',
            position: 'absolute',
            height: 'calc(100% - 4px)',
            width: 'calc(100% - 4px)',
            borderRadius: '4px',
            background: 'transparent',
            pointerEvents: 'none',
            transition: 'background 2s',
          },

          '& .tableRowAnimated .tableRowContent:before': {
            background: 'rgba(21, 129, 255, 0.08)',
            transition: 'background 0s',
          },

          '& .tableRowContent': {
            width: '100%',
            height: '100%',
            minHeight: 40,
            // display: 'flex',
            display: 'grid',
            // alignItems: 'flex-start',
            padding: '2px',
            borderRadius: '4px',
            position: 'relative',
          },

          '& .tableRowContentHighlighted': {
            background: '#47cc28',
            transition: '0.3s',
          },
          '& .tableRowContentActive': {
            '& .tableRowColumn': {
              transition: '0.7s',
              background: '#dadada',
            },
          },

          '& .tableRowContent.tableRowContentExpanded': {
            paddingBottom: '0px',
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },

          '& .tableRowExpandContainer': {
            backgroundColor: '#fff',
            borderBottomLeftRadius: '4px',
            borderBottomRightRadius: '4px',
            marginLeft: '2px',
            marginRight: '2px',
            padding: '2px',
            zIndex: '3 !important',
          },

          '& .tableRowExpandContent': {
            padding: '6px',
            borderRadius: '6px',
            backgroundColor: '#F7F7F7',
            borderBottomLeftRadius: '2px',
            borderBottomRightRadius: '2px',
            width: scrollable && containerWidth - 14,
            position: scrollable && 'sticky',
            left: scrollable && '8px',
          },
          '& .tableRowColumn': {
            height: '100%',
            overflow: 'hidden',
            backgroundColor: '#fff',
            display: 'flex',
            alignItems: 'center',
            boxSizing: 'border-box',
            '&.doubleRow': {
              height: 80,
            },
            '&.rowFillYellow': {
              border: '1px solid #EDBE18',
              backgroundColor: '#FBF2D1',
              borderLeftWidth: 0,
              borderRightWidth: 0,
            },

            '&.rowFillGrey': {
              border: '1px solid #B5C0CC',
              backgroundColor: '#E6EAEE',
              borderLeftWidth: 0,
              borderRightWidth: 0,
            },

            '&.columnFillGrey': {
              backgroundColor: '#E6EAEE',
              color: '#4C535A',
            },

            '&.columnFillRed': {
              backgroundColor: '#FFCCCF',
              color: '#9D1F27',
            },

            '&.columnFillBlack': {
              backgroundColor: 'rgb(63,63,63)',
              color: '#225BC8',
            },

            '&.columnFillOrange': {
              backgroundColor: '#FFD8B5',
              color: '#CF6200',
            },

            '&.columnFillGreen': {
              backgroundColor: '#BCF2D8',
              color: '#349064',
            },

            '&.columnFillYellow': {
              backgroundColor: '#FBF2D1',
              color: '#A78200',
            },

            '&.columnFillPurple': {
              backgroundColor: '#F1CBFF',
              color: '#8531A3',
            },

            '&.columnFillBlue': {
              backgroundColor: '#CBDDFF',
              color: '#225BC8',
            },

            '&.columnFillBlueLight': {
              backgroundColor: '#CBF3FF',
              color: '#2A6F85',
            },
          },

          '& .tableRowColumnExpand': {
            padding: '0 4px',
            minWidth: 34,
          },

          '& .tableRowColumn:first-of-type': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            borderLeftWidth: '1px',
          },

          '& .tableRowColumn:last-of-type': {
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            borderRightWidth: '1px',
          },

          '& .tableRowColumn.tableRowColumnLast': {
            flexGrow: 1,
          },

          '& .tableRowColumn.tableRowColumnDivided': {
            position: 'relative',
            // paddingRight: '8px',

            overflow: 'visible',
            '&::after': {
              content: '""',
              position: 'absolute',
              height: '24px',
              width: '1px',
              backgroundColor: '#8496AB',
              borderRadius: '0.5px',
              right: '0px',
            },
          },

          '& .tableRowColumn.doubleRow.tableRowColumnDivided': {
            '&::after': {
              height: '66px',
            },
          },

          '& .tableRowContent.tableRowContentExpanded .tableRowColumn:first-of-type':
            {
              borderBottomLeftRadius: '0px',
            },

          '& .tableRowContent.tableRowContentExpanded .tableRowColumn:last-of-type':
            {
              borderBottomRightRadius: '0px',
            },
        }}
      >
        {children}
      </Box>
    );
  }
);

export default TableBox;
