import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AvatarIcon from '@mui/icons-material/AccountCircle';
import { getLinksAndSchemas } from '../utils/schemaHelper';
import { Link } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import makeStyles from '@mui/styles/makeStyles';
import get from 'lodash/get';
import { useRoutes } from '../providers/RoutesProvider';

const useStyles = makeStyles({
  email: {
    '&:focus': {
      outline: 'none',
    },
  },
});

const UserMenu = ({ logout, user }) => {
  const classes = useStyles();
  const routes = useRoutes();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        edge="end"
        aria-owns={anchorEl ? 'user-menu' : null}
        aria-haspopup="true"
        onClick={handleClick}
        color="inherit"
        size="large"
      >
        <AvatarIcon />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {user && user.email && (
          <MenuItem divider button={false} className={classes.email}>
            {user.email}
          </MenuItem>
        )}
        {user ? (
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        ) : (
          Object.keys(routes)
            .filter(routeName =>
              get(getLinksAndSchemas(routes, routeName).uiSchema, 'ui:usermenu')
            )
            .map(routeName => (
              <MenuItem
                key={routeName}
                component={Link}
                to={{
                  pathname: routeName,
                }}
              >
                {routes[routeName].summary}
              </MenuItem>
            ))
        )}
      </Menu>
    </div>
  );
};

export default UserMenu;
