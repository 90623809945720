import { gql } from '@apollo/client';

const createMatch = gql`
  mutation createMatch(
    $sportId: SportId!
    $groupId: GroupId!
    $providerId: ProviderId!
    $provider: Provider!
    $eventCreateModel: MatchCreateModel!
  ) {
    createMatch(
      sportId: $sportId
      groupId: $groupId
      providerId: $providerId
      provider: $provider
      eventCreateModel: $eventCreateModel
    ) {
      booked {
        desc {
          id
        }
      }
    }
  }
`;

export default createMatch;
