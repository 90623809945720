import { useQuery } from '@apollo/client';
import { useCallback, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicSelect from '../../../components/Inputs/DynamicSelect/DynamicSelect';
import Select from '../../../components/Inputs/Select/Select';
import UserSelect from '../../../components/Inputs/UserSelect/UserSelect';
import TableActionCards from '../../../components/TableActionCards/TableActionCards';
import TablePagination from '../../../components/TablePagination/TablePagination';
import getBrandNames from '../../../gql/sportbook-config/line-settings/getBrands';
import getTemplateNames from '../../../gql/sportbook-config/line-settings/getTemplateNames';
import getTemplatesTable from '../../../gql/sportbook-config/templates/queries/getTemplatesTable';
import useFilters from '../../../hooks/useFilters';
import { withoutEmpty } from '../../../hooks/usePresetFilters';
import useTablePagination from '../../../hooks/useTablePagination';
import TemplatesTable from './TemplatesTable';
import { tableNames } from '../../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../../components/ErrorWidget';

export const defaultFilters = {
  types: null,
  templateIds: [],
  brandIds: null,
  updatedBy: [],
};

export const TEMPLATE_TYPE = {
  COVERAGE: 'coverage',
  SOURCES: 'sources',
  MARGIN: 'margin',
};

const TemplatesTab = () => {
  const { limit, setLimit, offset, setOffset } = useTablePagination({
    tableName: tableNames.TemplatesTab,
    onChangeLimit: () => {},
  });

  const navigate = useNavigate();

  const refetchDataRef = useRef(() => {});

  const refetchData = useCallback(() => {
    return refetchDataRef.current();
  }, []);

  const { filtersCard, filters } = useFilters({
    tableName: tableNames.TemplatesTab,
    resetOffset: setOffset,
    onResubmit: refetchData,
    defaultValues: defaultFilters,
    filters: [
      {
        name: 'types',
        label: 'Type',
        component: (
          <Select
            multiple
            options={[
              {
                label: 'Coverage',
                value: TEMPLATE_TYPE.COVERAGE,
              },
              // {
              //   label: 'Margin',
              //   value: TEMPLATE_TYPE.MARGIN,
              // },
              {
                label: 'Sources',
                value: TEMPLATE_TYPE.SOURCES,
              },
            ]}
          />
        ),
        toggled: true,
      },
      {
        name: 'templateIds',
        label: 'Name',
        component: ({ types, ...props }) => {
          return (
            <DynamicSelect
              multiple
              label={'Name'}
              gqlQuery={getTemplateNames}
              variables={{ templateTypes: types || [] }}
              {...props}
              disabled={false}
            />
          );
        },
        toggled: true,
        deps: ['types'],
      },
      {
        name: 'brandIds',
        label: 'Brand name',
        component: (
          <DynamicSelect
            multiple
            innerOptionKey={'brand'}
            label={'Brand names'}
            gqlQuery={getBrandNames}
          />
        ),
        toggled: true,
      },
      {
        name: 'updatedBy',
        label: 'Updated by',
        component: <UserSelect multiple />,
        toggled: true,
      },
    ],
  });

  const queryFilters = useMemo(() => {
    return {
      limit,
      offset,
      ...withoutEmpty(filters),
    };
  }, [limit, offset, filters]);

  const {
    data: { data } = {},
    refetch,
    loading,
    error,
  } = useQuery(getTemplatesTable, {
    variables: {
      filters: queryFilters,
    },
    fetchPolicy: 'no-cache',
  });

  refetchDataRef.current = refetch;

  const items = useMemo(() => {
    return (data && data.items) || [];
  }, [data]);

  const total = useMemo(() => {
    return (data && data.total) || 1;
  }, [data]);

  const actions = useMemo(() => {
    return [filtersCard];
  }, [filtersCard]);

  const rowActions = useMemo(
    () => [
      {
        key: 'editTemplate',
        label: 'Edit',
        onClick: item => {
          navigate(`/line-settings/template/edit/${item.id}`, { state: item });
        },
      },
      {
        key: 'editTemplateDuplicate',
        label: 'Duplicate',
        onClick: item => {
          navigate(`/line-settings/template/edit/${item.id}?duplicate=true`, {
            state: item,
          });
        },
      },
    ],
    [navigate]
  );

  if (error) {
    return <ErrorWidget />;
  }

  return (
    <>
      <TableActionCards animated actions={actions} inline />
      <TemplatesTable
        data={items}
        loading={loading}
        error={error}
        rowActions={rowActions}
      />
      <TablePagination
        offset={offset}
        setOffset={setOffset}
        limit={limit}
        setLimit={setLimit}
        totalRows={total}
      />
    </>
  );
};

export default TemplatesTab;
