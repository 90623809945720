import React, { useContext, useEffect, useMemo, useState } from 'react';
import prepareSchema from '../utils/prepareSchema';
import { getActiveSchemaUrl } from '../reducers/schemaUrls';
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../reducers/users';
import { refreshToken } from '../actions/authActions';
import usePrevious from '../hooks/usePrevious';

export const RawSchemaContext = React.createContext({});

export const useRawSchema = () => {
  return useContext(RawSchemaContext);
};

const RawSchemaProvider = ({ mocked, children }) => {
  const activeSchemaUrl = useSelector(getActiveSchemaUrl);
  const [preparedSchema, setPreparedSchema] = useState({});
  const [error, setError] = useState(null);
  const user = useSelector(getUser);
  const previousActiveSchemaUrl = usePrevious(activeSchemaUrl);
  const previousUser = usePrevious(user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tokenRefreshed =
      previousUser && user && previousUser.token !== user.token;
    const schemaChanged = activeSchemaUrl !== previousActiveSchemaUrl;
    const loginStateChanged =
      previousUser?.token !== user?.token && !tokenRefreshed;

    if (mocked) {
      return;
    }

    if (schemaChanged || loginStateChanged) {
      setPreparedSchema({});
      setError(null);
      setLoading(true);

      prepareSchema(activeSchemaUrl, user && user.token)
        .then(preparedSchema => {
          setPreparedSchema(preparedSchema);
        })
        .catch(e => {
          if (
            e.code === 401 ||
            e.message === '401' ||
            e.code === 403 ||
            e.message === '403'
          ) {
            dispatch(refreshToken());
          } else {
            console.log(e);
            setError(e);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [
    activeSchemaUrl,
    dispatch,
    mocked,
    previousActiveSchemaUrl,
    previousUser,
    user,
  ]);

  const providerValue = useMemo(
    () => ({
      error,
      // rawSchema: preparedSchema.rawSchema,
      loading,
      // schema: preparedSchema.schema,
      routes: preparedSchema.routes,
      apiHost: preparedSchema.apiHost,
      apiBaseUrl: preparedSchema.apiBaseUrl,
      webSocketHost: preparedSchema.webSocketHost,
    }),
    [error, preparedSchema, loading]
  );

  return (
    <RawSchemaContext.Provider value={mocked || providerValue}>
      {children}
    </RawSchemaContext.Provider>
  );
};

export default RawSchemaProvider;
