import TableCell from '../components/Table/TableCell';
import TableRow from '../components/Table/TableRow';
import { useCallback } from 'react';
import InlineTableForm from '../components/Table/InlineTableForm';

export const commonCellStyle = {
  padding: '0px 8px',
};

export const centeredCellStyle = {
  ...commonCellStyle,
  justifyContent: 'center',
  textAlign: 'center',
};

const useTableRowsRender = ({
  visibleColumns,
  template,
  rowActions,
  bulk,
  checkIfRowExpandable,
  inlineForm = false,
  highlightedRow = null,
  activeRow = null,
  inlineFormProps: {
    mode,
    schema,
    onChange,
    onSuccessChange,
    onSubmit,
    debounceTime,
  } = {},

  ...props
}) => {
  const renderCells = useCallback(
    (rowData, summary) => {
      return visibleColumns
        ? visibleColumns.map(column => (
            <TableCell
              key={column.key}
              name={column.key}
              deps={column.deps}
              rowData={rowData}
              cellClassName={column.cellClassName}
              render={column.render}
              summary={summary}
              direction={column.direction}
              justifyContent={column.justifyContent}
              alignItems={column.alignItems}
              textAlign={column.textAlign}
              minWidth={column.minWidth}
              style={{
                ...commonCellStyle,
                ...column.style,
              }}
            />
          ))
        : null;
    },
    [visibleColumns]
  );

  return useCallback(
    ({
      rowIndex,
      rowData,
      rowId,
      isVisible,
      summary,
      isSummary,
      withUndo,
      onUndo,
    }) => {
      const row = (
        <TableRow
          isHighlighted={highlightedRow === rowData.groupId}
          isActive={activeRow === rowData.groupId}
          gridTemplate={template}
          rowId={rowId}
          rowIndex={rowIndex}
          rowData={rowData}
          renderCells={renderCells}
          rowActions={rowActions}
          isVisible={isVisible}
          bulk={bulk}
          summary={summary}
          isSummary={isSummary}
          withUndo={withUndo}
          onUndo={onUndo}
          withExpandButton={
            checkIfRowExpandable ? checkIfRowExpandable(rowData) : true
          }
          {...props}
        />
      );

      if (inlineForm) {
        return (
          <InlineTableForm
            mode={mode}
            schema={schema}
            onChange={onChange}
            onSuccessChange={onSuccessChange}
            onSubmit={onSubmit}
            defaultValues={rowData}
            rowId={rowId}
            debounceTime={debounceTime}
          >
            {row}
          </InlineTableForm>
        );
      }

      return row;
    },
    [
      rowActions,
      renderCells,
      template,
      bulk,
      props,
      onSuccessChange,
      onChange,
      schema,
      inlineForm,
      mode,
      onSubmit,
      debounceTime,
      checkIfRowExpandable,
      highlightedRow,
      activeRow,
    ]
  );
};

export default useTableRowsRender;
