import { gql } from '@apollo/client';

const getMatchCreateModel = gql`
  query getMatchCreateModel(
    $sportId: SportId!
    $groupId: GroupId!
    $providerId: ProviderId!
    $provider: Provider!
  ) {
    getMatchCreateModel(
      sportId: $sportId
      groupId: $groupId
      providerId: $providerId
      provider: $provider
    ) {
      category {
        langs
        id
        countryCode
      }
      tournament {
        langs
        id
      }
      homeTeam {
        langs
        id
        countryCode
      }
      awayTeam {
        langs
        id
        countryCode
      }
      autocreate
    }
  }
`;

export default getMatchCreateModel;
