import { useLazyQuery, useMutation, useSubscription } from '@apollo/client';
import { createApi, createStore } from 'effector';
import { useStore } from 'effector-react';
import isEqual from 'lodash/isEqual';
import orderBy from 'lodash/orderBy';
import uniqWith from 'lodash/uniqWith';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useResizeObserver from 'use-resize-observer';
import { useNavigate } from 'react-router-dom';
import FancyBrandSelect from '../../components/Inputs/BrandSelect/FancyBrandSelect';
import BetTypeSelect from '../../components/Inputs/BetTypeSelect/BetTypeSelect';
import BetStatusSelect from '../../components/Inputs/BetStatusSelect/BetStatusSelect';
import Checkbox from '../../components/Inputs/Checkbox';
import CountrySelect from '../../components/Inputs/CountrySelect/CountrySelect';
import DeviceSelect from '../../components/Inputs/DeviceSelect/DeviceSelect';
import FancyMultiAutocomplete from '../../components/Inputs/FancyAutocomplete/FancyMultiAutocomplete';
import MarketProviderSelect from '../../components/Inputs/MarketProviderSelect/MarketProviderSelect';
import NumberField from '../../components/Inputs/NumberField';
import OperatorFilterSelect from '../../components/Inputs/OperatorSelect/OperatorFilterSelect';
import RejectCodeSelect from '../../components/Inputs/RejectCodeSelect/RejectCodeSelect';
import RejectReasonSelect from '../../components/Inputs/RejectReasonSelect/RejectReasonSelect';
import TextField from '../../components/Inputs/TextField';
import Page from '../../components/Page/Page';
import TableActionCards from '../../components/TableActionCards/TableActionCards';
import getCategoriesNamesInline from '../../gql/autocomplete/queries/getCategoriesNamesInLine';
import getEventsNamesInline from '../../gql/autocomplete/queries/getEventsNamesInLine';
import getSportNamesInLine from '../../gql/autocomplete/queries/getSportNamesInLine';
import getTournamentsNamesInline from '../../gql/autocomplete/queries/getTournamentsNamesInLine';
import BETSTREAM_BET_UNWATCH from '../../gql/mutations/BETSTREAM_BET_UNWATCH';
import BETSTREAM_BET_WATCH from '../../gql/mutations/BETSTREAM_BET_WATCH';
import BETSTREAM_WATCH_SUBSCRIPTION from '../../gql/subscriptions/BETSTREAM_WATCH_SUBSCRIPTION';
import ADMIN_BETSTREAM_SUBSCRIPTION from '../../gql/subscriptions/ADMIN_BETSTREAM_SUBSCRIPTION';
import OPERATOR_BETSTREAM_SUBSCRIPTION from '../../gql/subscriptions/OPERATOR_BETSTREAM_SUBSCRIPTION';
import useDebounced from '../../hooks/useDebounced';
import useFiltersQuery from '../../hooks/useFiltersQuery';
import usePresetFilters, { withoutEmpty } from '../../hooks/usePresetFilters';
import usePresetsActionCard from '../../hooks/usePresetsActionCard';
import useTableAnimatedRow from '../../hooks/useTableAnimatedRow';
import useTableExpandRows from '../../hooks/useTableExpandRows';
import { useIsOperator } from '../../providers/OperatorProvider';
import { usePresets } from '../../providers/PresetsProvider';
import TableExpandRowProvider from '../../providers/TableExpandRowProvider';
import TableAnimatedRowProvider from '../../providers/TableAnimatedRowProvider';
import BetstreamTable, {
  columnsOrderConfig,
  columnsWidthConfig,
  initialToggledColumns,
  COLUMN,
} from './BetstreamTable';
import useDynamicQuery from '../../hooks/useDynamicQuery';
import { tableNames } from '../../hooks/useLocalStorageTableConfig';
import ErrorWidget from '../../components/ErrorWidget';

const sortBets = bets => {
  return orderBy(bets, ['watch', 'created_at'], ['desc', 'desc']);
};

const uniqBets = bets => {
  return uniqWith(bets, (item, otherItem) => {
    return item.bet_id === otherItem.bet_id;
  });
};

const limitBets = (data, limit) => {
  if (data.length <= limit) {
    return data;
  }

  return data.splice(0, limit);
};

const $bets = createStore({
  loading: true,
  items: [],
});

// MAP FOR KEEP COMPATIBILITY BETWEEN OLD BETSTREAM MODEL FILTERS AND NEW AUTOCOMPLETE API
const filterKeysMap = {
  sportIds: 'sport_ids',
  categoriesIds: 'categories_ids',
  tournamentsIds: 'tournaments_ids',
  eventsIds: 'events_ids',
};

const { addBet, addBetsFromQueue, addBets, setBets, watch, setLoading } =
  createApi($bets, {
    addBet: (bets, { bet, limit }) => {
      const exists = bets.items.find(item => item.bet_id === bet.bet_id);

      if (exists) {
        return {
          ...bets,
          items: bets.items.map(item => {
            if (item.bet_id === bet.bet_id) {
              return {
                ...item,
                ...bet,
              };
            }

            return item;
          }),
        };
      }

      return {
        ...bets,
        items: limitBets(sortBets([bet, ...bets.items]), 500),
      };
    },
    addBetsFromQueue: (bets, { newBets }) => {
      const betsToUpdate = [];
      const betsToAdd = [];

      newBets.forEach(bet => {
        const isExists = !!bets.items.find(item => item.bet_id === bet.bet_id);
        (isExists ? betsToUpdate : betsToAdd).push(bet);
      });

      const updatedItems = bets.items.map(item => {
        const updatedBetData = betsToUpdate.find(
          bet => bet.bet_id === item.bet_id
        );

        if (updatedBetData) {
          return {
            ...item,
            ...updatedBetData,
          };
        }
        return item;
      });

      return {
        ...bets,
        items: limitBets(
          sortBets([...betsToAdd.reverse(), ...updatedItems]),
          500
        ),
      };
    },
    setBets: (bets, { newBets }) => {
      return {
        items: newBets,
        loading: false,
      };
    },
    addBets: (bets, { newBets }) => {
      return {
        items: limitBets(uniqBets(sortBets([...bets.items, ...newBets])), 500),
        loading: false,
      };
    },
    setLoading: (bets, loading) => ({
      ...bets,
      loading,
    }),
    watch: (bets, { bet_id, watch }) => {
      return {
        ...bets,
        items: sortBets(
          bets.items.map(bet => {
            if (bet.bet_id === bet_id) {
              return {
                ...bet,
                watch,
              };
            }

            return bet;
          })
        ),
      };
    },
  });

const Subscriptions = ({
  variables,
  onSubscriptionData,
  onWatchSubscriptionData,
  isOperator,
  onError,
  onWatchError,
}) => {
  const { error } = useSubscription(
    isOperator ? OPERATOR_BETSTREAM_SUBSCRIPTION : ADMIN_BETSTREAM_SUBSCRIPTION,
    {
      variables,
      onSubscriptionData,
      onError,
    }
  );

  const { error: watchError } = useSubscription(BETSTREAM_WATCH_SUBSCRIPTION, {
    fetchPolicy: 'no-cache',
    variables: {},
    onSubscriptionData: onWatchSubscriptionData,
    onError: onWatchError,
  });

  if (watchError || error) {
    return <ErrorWidget />;
  }

  return null;
};

const transformParam = (value, paramKey) => {
  if (!value) {
    return {};
  }

  return {
    [`exclude_${paramKey}`]: !!value.exclude,
    [paramKey]: value.items,
  };
};

const resolveInitialColumns = (isOperator, columns) =>
  isOperator
    ? columns.filter(
        c =>
          c !== COLUMN.MARKET_PROVIDERS &&
          c !== COLUMN.DELAY &&
          c !== COLUMN.RELIABILITY_COEFFICIENT
      )
    : columns;

const BetstreamPage = ({}) => {
  const isOperator = useIsOperator();
  const [buildingQuery, setBuildingQuery] = useState(false);

  const [visibleColumns, setVisibleColumns] = useState(
    resolveInitialColumns(isOperator, initialToggledColumns)
  );

  const navigate = useNavigate();
  const { items: bets, loading } = useStore($bets);
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 50,
  });

  const actionCardsRef = useRef(null);
  const tableRef = useRef(null);
  const betsQueueRef = useRef([]);

  const [tableOffsetTop, setTableOffsetTop] = useState(0);
  const [subscriptionError, setSubscriptionError] = useState(false);

  useResizeObserver({
    ref: actionCardsRef,
    onResize: rect => {
      setTableOffsetTop(rect.height);
    },
  });

  const { activePreset, updatePreset, loading: presetsLoading } = usePresets();

  const [filtersQuery] = useFiltersQuery(
    activePreset && activePreset.data.filters
  );

  const columnsWidth = useMemo(() => {
    return activePreset &&
      activePreset.data.columns_width &&
      Object.keys(activePreset.data.columns_width).length
      ? { ...columnsWidthConfig, ...activePreset.data.columns_width }
      : columnsWidthConfig;
  }, [activePreset]);

  const toggledColumns = useMemo(() => {
    if (!activePreset || !activePreset.data.toggled_columns.length) {
      return resolveInitialColumns(isOperator, initialToggledColumns);
    }
    const presetColumns = activePreset.data.toggled_columns;
    setVisibleColumns(presetColumns);

    return presetColumns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePreset]);

  const columnsOrder = useMemo(() => {
    const orderConfig = columnsOrderConfig(isOperator);

    if (activePreset) {
      if (
        activePreset.data.columns_order.length &&
        activePreset.data.columns_order.length === orderConfig.length
      ) {
        return activePreset.data.columns_order;
      }

      return orderConfig;
    }

    return orderConfig;
  }, [activePreset, isOperator]);

  const [filters, setFilters] = useState(
    [
      {
        name: 'sportIds',
        label: 'Sport',
        fullWidth: true,
        component: (
          <FancyMultiAutocomplete
            gqlQuery={getSportNamesInLine}
            keepSelectedOptions
          />
        ),
        toggled: true,
      },
      {
        name: 'categoriesIds',
        label: 'Category',
        fullWidth: true,
        component: ({ sportIds, ...props }) => {
          return (
            <FancyMultiAutocomplete
              gqlQuery={getCategoriesNamesInline}
              {...props}
              keepSelectedOptions
              params={{
                ...transformParam(sportIds, 'sportIds'),
              }}
            />
          );
        },
        toggled: true,
        deps: ['sportIds'],
      },
      {
        name: 'tournamentsIds',
        label: 'Tournament',
        fullWidth: true,
        component: ({ sportIds, categoriesIds, ...props }) => (
          <FancyMultiAutocomplete
            gqlQuery={getTournamentsNamesInline}
            params={{
              ...transformParam(sportIds, 'sportIds'),
              ...transformParam(categoriesIds, 'categoriesIds'),
            }}
            keepSelectedOptions
          />
        ),
        toggled: true,
        deps: ['sportIds', 'categoriesIds'],
      },
      {
        name: 'eventsIds',
        label: 'Event',
        fullWidth: true,
        component: ({ sportIds, categoriesIds, tournamentsIds, ...props }) => (
          <FancyMultiAutocomplete
            gqlQuery={getEventsNamesInline}
            params={{
              ...transformParam(sportIds, 'sportIds'),
              ...transformParam(categoriesIds, 'categoriesIds'),
              ...transformParam(tournamentsIds, 'tournamentsIds'),
            }}
            keepSelectedOptions
          />
        ),
        toggled: true,
        deps: ['sportIds', 'categoriesIds', 'tournamentsIds'],
      },
      {
        name: 'player_id',
        label: 'Player ID',
        component: <TextField fullWidth />,
        toggled: true,
      },
      {
        name: 'stake_from',
        label: 'Stake from',
        component: <NumberField fullWidth />,
        toggled: true,
      },
      {
        name: 'source',
        label: 'Source',
        component: <DeviceSelect multiple />,
        toggled: true,
      },
      {
        name: 'status',
        label: 'Status',
        component: <BetStatusSelect multiple />,
        toggled: true,
      },
      {
        name: 'bet_type',
        label: 'Bet type',
        component: <BetTypeSelect multiple />,
        toggled: true,
      },
      {
        name: 'operator',
        label: 'Operator',
        component: <OperatorFilterSelect multiple proxyValue />,
        toggled: true,
        hidden: isOperator,
      },
      {
        name: 'brand',
        label: 'Brand',
        component: ({ operator, ...props }) => {
          return (
            <FancyBrandSelect
              operatorIds={operator}
              multiple
              {...props}
              proxyValue
            />
          );
        },
        toggled: true,
        deps: ['operator'],
        hidden: isOperator,
      },
      {
        name: 'brand',
        label: 'Brand',
        component: (
          <FancyBrandSelect multiple proxyValue withOperator={false} />
        ),
        toggled: true,
        hidden: !isOperator,
      },
      {
        name: 'net_win_from',
        label: 'Net win from',
        component: <NumberField fullWidth />,
        toggled: true,
      },
      {
        name: 'players_country',
        label: 'Players country',
        component: <CountrySelect multiple />,
        toggled: true,
      },
      {
        name: 'market_providers',
        label: 'Market providers',
        component: <MarketProviderSelect multiple type="betstream" />,
        toggled: true,
        hidden: isOperator,
      },
      {
        name: 'reject_reason_type',
        label: 'Reject reason',
        component: <RejectReasonSelect multiple />,
        toggled: true,
        hidden: isOperator,
      },
      {
        name: 'reject_stake_to',
        label: 'Rejected Max Stake',
        component: <NumberField fullWidth />,
        toggled: true,
      },
      {
        name: 'max_stake_to_limit',
        label: 'Rejected Max Stake to limit coef',
        component: <NumberField fullWidth />,
        toggled: true,
      },
      {
        name: 'include_reject_code',
        label: 'Include Reject Code',
        component: <RejectCodeSelect multiple />,
        toggled: true,
      },
      {
        name: 'live',
        label: 'Live',
        component: <Checkbox />,
        toggled: true,
      },
      {
        name: 'prematch',
        label: 'Prematch',
        component: <Checkbox />,
        toggled: true,
      },
      {
        name: 'bonus',
        label: 'Bonus',
        component: <Checkbox indeterminateMode />,
        toggled: true,
      },
      {
        name: 'virtual',
        label: 'Virtual',
        component: <Checkbox indeterminateMode />,
        toggled: true,
      },

      {
        name: 'mts',
        label: 'Mts',
        component: <Checkbox indeterminateMode />,
        toggled: true,
        hidden: isOperator,
      },

      {
        name: 'from_fake_brand',
        label: 'From fake brand',
        component: <Checkbox indeterminateMode />,
        toggled: true,
        hidden: isOperator,
      },

      {
        name: 'is_bet_builder',
        label: 'Bet builder',
        component: <Checkbox indeterminateMode />,
        toggled: true,
      },
      {
        name: 'has_operator_bonus',
        label: 'Has Operator Bonus',
        component: <Checkbox />,
      },
      {
        name: 'deep_monitoring',
        label: 'Deep monitoring',
        component: <Checkbox />,
        hidden: isOperator,
      },
    ]
      .filter(item => !item.hidden)
      .map(item => {
        if (filterKeysMap[item.name]) {
          return { ...item, name: filterKeysMap[item.name] };
        }
        return item;
      })
  );

  const limit = pagination.limit;
  const offset = pagination.offset;

  const onToggleColumns = useCallback(
    toggledColumns => {
      setBuildingQuery(true);
      updatePreset(activePreset.id, {
        ...activePreset,
        data: {
          ...activePreset.data,
          toggled_columns: toggledColumns,
        },
      });
      setPagination({
        ...pagination,
        offset: 0,
      });
      setVisibleColumns(toggledColumns);
    },
    [updatePreset, activePreset, pagination]
  );

  const filterKeys = useMemo(() => {
    return filters.map(item => item.name);
  }, [filters]);

  const queryVariables = useMemo(() => {
    return {
      input: {
        ...withoutEmpty(
          filterKeys.reduce((acc, key) => {
            return {
              ...acc,
              [key]: filtersQuery[key],
            };
          }, {})
        ),
        limit,
        offset,
      },
    };
  }, [filterKeys, filtersQuery, limit, offset]);

  const { query } = useDynamicQuery('BetStreamModel', {
    fields: visibleColumns,
    template: fields => [
      'watch',
      'created_at',
      'bet_id',
      {
        statistics: ['bets', 'ggr', 'payout', 'turnover', 'margin'],
      },
      {
        vip_request_result: ['comment', 'resolved_by', 'result_type'],
      },
      ...(isOperator ? [] : ['delay', 'reliability_coefficient']),
      ...fields,
    ],
    variables: {
      input: {
        type: 'BetStreamModelFilters',
        required: false,
      },
    },
    mapper: {
      [COLUMN.WATCH]: ['bet_id', 'watch'],
      [COLUMN.DEVICE]: ['source'],
      [COLUMN.USERNAME]: [
        'ccf',
        'segment_reviewed',
        'player_id',
        'username',
        'rm_comment',
        ...(isOperator ? [] : ['deep_monitoring']),
      ],
      [COLUMN.EXT_PLAYER_ID]: [
        'ext_player_id',
        'bookmaker_id',
        'limit_id',
        'player_id',
      ],
      [COLUMN.BRAND_ID]: ['brand', 'brand_id', 'operator'],
      [COLUMN.SPORT]: [
        {
          sport: [
            'sport',
            'sport_id',
            ...(isOperator ? [] : ['market_providers']),
          ],
        },
        'odds',
      ],
      [COLUMN.STAKE_EUR]: ['stake_EUR', 'currency', 'stake'],
      [COLUMN.NET_WIN_EUR]: ['net_win_EUR', 'net_win', 'currency'],
      [COLUMN.BONUS]: ['bonus_type', 'bonus', 'bonus_id'],
      [COLUMN.OPERATOR_BONUS]: [
        'operator_bonus',
        'operator_bonus_EUR',
        'currency',
      ],
      [COLUMN.STATUS]: ['status', 'bet_status', 'reject_reason', 'reject_code'],
      [COLUMN.INFO]: [
        'bet_status',
        'stake_EUR',
        {
          sport: [
            'type',
            'sport',
            'category',
            'tournament',
            'event',
            'outcome',
            'market',
            'odds',
          ],
        },
      ],

      // SELECTION COLUMNS
      [COLUMN.EVENT_ID]: [{ sport: ['event', 'event_id'] }],
      [COLUMN.TOURNAMENT]: [{ sport: ['tournament'] }],
      [COLUMN.CATEGORY]: [{ sport: ['category'] }],
      [COLUMN.MARKET]: [{ sport: ['market'] }],
      [COLUMN.MARKET_PROVIDERS]: [{ sport: ['market_providers'] }],
      [COLUMN.OUTCOME]: [{ sport: ['outcome'] }],
      [COLUMN.TYPE]: [{ sport: ['type', 'event_scheduled'] }],
      [COLUMN.ODDS]: [{ sport: ['odds'] }],
      [COLUMN.SCORE]: [{ sport: ['score'] }],
    },
  });

  const [fetchBets, { refetch: refetchBets, loading: betsLoading, error }] =
    useLazyQuery(query, {
      fetchPolicy: 'no-cache',
      variables: queryVariables,
      onCompleted: ({ BetStreamModel: betsData } = {}) => {
        if (buildingQuery) {
          setBets({
            newBets: betsData,
            pagination: {
              limit: pagination.limit,
              offset: 0,
            },
          });
          setBuildingQuery(false);
        } else {
          addBets({
            newBets: betsData,
            pagination: {
              limit: pagination.limit,
              offset: pagination.offset,
            },
          });
        }
      },
    });

  useEffect(() => {
    const handleScroll = () => {
      window.requestAnimationFrame(() => {
        const isTableOnTop =
          tableRef.current?.tableBoxEl?.getBoundingClientRect().top < 0;

        if (!isTableOnTop && betsQueueRef.current.length) {
          addBetsFromQueue({ newBets: betsQueueRef.current });
          betsQueueRef.current = [];
        }
      });
    };

    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (queryVariables && !presetsLoading) {
      fetchBets();
    }
  }, [queryVariables, fetchBets, presetsLoading, visibleColumns]);

  const [watchBet, { error: watchError }] = useMutation(BETSTREAM_BET_WATCH);

  const [unWatchBet, { error: unwatchError }] = useMutation(
    BETSTREAM_BET_UNWATCH
  );
  const animateRows = useTableAnimatedRow();

  const onSubscriptionData = useCallback(
    ({ subscriptionData: { data: { BetStreamModel: newBetData } = {} } }) => {
      const isTableOnTop =
        tableRef.current?.tableBoxEl?.getBoundingClientRect().top < 0;

      if (isTableOnTop) {
        betsQueueRef.current = uniqBets([...betsQueueRef.current, newBetData]);
      } else {
        addBet({ bet: newBetData, limit });
        tableRef.current.recalculateSizes();
      }

      // setPagination(current => ({
      //   ...current,
      //   offset: current.offset + 1,
      // }));
      // animateRows.animateRow(newBetData.bet_id);
    },
    [limit]
  );

  const onWatchSubscriptionData = useCallback(
    ({ subscriptionData: { data: { WatchModel: newWatchData } = {} } }) => {
      watch(newWatchData);
    },
    []
  );

  const onWatchBet = useCallback(
    (bet_id, toggled) => {
      animateRows.animateRow(bet_id);

      watch({
        bet_id,
        watch: toggled,
      });

      const variables = {
        bet_id,
      };

      if (toggled) {
        watchBet({
          variables,
        });
      } else {
        unWatchBet({
          variables,
        });
      }
    },
    [watchBet, unWatchBet, animateRows]
  );

  const resubmit = useCallback(() => {
    setLoading(true);
    setPagination({
      offset: 0,
      limit: 50,
    });
    refetchBets().then(({ data: { BetStreamModel: items } }) => {
      setBets({ newBets: items });
    });
  }, [refetchBets]);

  useEffect(() => {
    setPagination({
      offset: 0,
      limit: 50,
    });
    setBets({ newBets: [] });
  }, [setPagination, filtersQuery]);

  const { filtersCard: filtersActionCard } = usePresetFilters({
    tableName: tableNames.BetStream,
    onResubmit: resubmit,
    filters,
  });

  const presetsActionCard = usePresetsActionCard({
    tableName: tableNames.BetStream,
  });

  const tableExpandRows = useTableExpandRows();

  const onChangeColumnsWidth = useCallback(
    columnsWidth => {
      if (activePreset) {
        updatePreset(activePreset.id, {
          ...activePreset,
          data: {
            ...activePreset.data,
            columns_width: columnsWidth,
          },
        });
      }
    },
    [activePreset, updatePreset]
  );

  const onReorderColumns = useCallback(
    columnsOrder => {
      if (activePreset) {
        updatePreset(activePreset.id, {
          ...activePreset,
          data: {
            ...activePreset.data,
            columns_order: columnsOrder,
          },
        });
      }
    },
    [activePreset, updatePreset]
  );

  const debouncedOnResizeColumns = useDebounced(onChangeColumnsWidth, 400);

  const actions = useMemo(() => {
    return [presetsActionCard, filtersActionCard];
  }, [presetsActionCard, filtersActionCard]);

  const rowActions = useMemo(() => {
    return [
      {
        key: 'playerProfile',
        label: 'Player Profile',
        onClick: ({ player_id }) => {
          navigate(`/api/v1/OperatorAPI/players/v3/${player_id}/get`);
        },
      },
      {
        key: 'eventDetails',
        label: 'Event Details',
        onClick: ({ sport }) => {
          navigate(`/events/${sport[0].event_id}`);
        },
      },
      {
        key: 'betslipDetails',
        label: 'Betslip Details',
        onClick: ({ bet_id }) => {
          navigate(`/api/v1/BetSlipsAdmin/ch/bet/${bet_id}/get`);
        },
      },
      {
        key: 'bonusDetails',
        label: 'Bonus Details',
        disabled: ({ bonus_id }) => !bonus_id,
        onClick: ({ bonus_id }) => {
          navigate(`/api/v1/BonusAdmin/bonus/${bonus_id}/get`);
        },
      },
    ];
  }, [navigate]);

  const {
    input: { offset: queryOffset, ...subscriptionQueryVariables },
  } = queryVariables;

  if (error || subscriptionError || watchError || unwatchError) {
    return <ErrorWidget />;
  }

  return (
    <Page title={'Betstream'}>
      {queryVariables && queryVariables.input && queryVariables.input.limit && (
        <Subscriptions
          onError={() => setSubscriptionError(true)}
          onWatchError={() => setSubscriptionError(true)}
          variables={{ input: subscriptionQueryVariables }}
          onSubscriptionData={onSubscriptionData}
          onWatchSubscriptionData={onWatchSubscriptionData}
          isOperator={isOperator}
        />
      )}

      <TableActionCards actions={actions} ref={actionCardsRef} />

      {activePreset && (
        <TableAnimatedRowProvider value={animateRows}>
          <TableExpandRowProvider value={tableExpandRows}>
            <BetstreamTable
              isOperator={isOperator}
              ref={tableRef}
              data={bets}
              loading={loading || buildingQuery}
              columnsWidth={columnsWidth}
              onChangeColumnsWidth={debouncedOnResizeColumns}
              toggledColumns={toggledColumns}
              onToggleColumns={onToggleColumns}
              order={columnsOrder}
              onReorder={onReorderColumns}
              onWatchBet={onWatchBet}
              rowActions={rowActions}
              tableOffsetTop={tableOffsetTop}
              setPagination={setPagination}
            />
          </TableExpandRowProvider>
        </TableAnimatedRowProvider>
      )}
    </Page>
  );
};

export default BetstreamPage;
