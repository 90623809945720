import Grid from '@mui/material/Grid/Grid';
import FormField from '../../../../components/Form/FormField';
import Tabs from '../../../../components/Tabs/Tabs';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import LocaleTabLabel from '../../../../components/Tabs/LocaleTabLabel';
import Button from '../../../../components/Button/Button';
import { useCallback, useRef, useState } from 'react';
import TabContent from '../../../../components/Tabs/TabContent';
import LocaleLangSelect from '../../../../components/Inputs/LocaleLangSelect/LocaleLangSelect';
import LocaleCountrySelect from '../../../../components/Inputs/LocaleCountrySelect/LocaleCountrySelect';
import isoLangs from '../../../../../constants/isoLangs';
import isoCountries from '../../../../../constants/isoCountries';
import { useImagePreviews } from '../../providers/ImagePreviewProvider';
import { BannerImageType } from '../BannerForm/BannerValidationSchema';

const getImagePreviewName = (name, index, imageType) =>
  `${name}.${index}.images.${imageType}`;

const isDefaultLocale = ({ lang, country }) => {
  return lang === 'all' && (!country || country === 'all');
};

const LocaleTabs = ({ name, content, onTabChange }) => {
  const cachedLocales = useRef({});

  const { control, getValues, setValue } = useFormContext();

  const { getPreviewValue, setPreviewValue, setPreviews } = useImagePreviews();

  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = useCallback(
    index => {
      setActiveTab(index);
      if (onTabChange) onTabChange(index);
    },
    [onTabChange]
  );

  const { fields, append, remove } = useFieldArray({
    control,
    name,
    shouldUnregister: false,
  });

  const handleNewImagePreviews = useCallback(
    tabIndex => {
      const defaultDesktopImage = getPreviewValue(
        getImagePreviewName(name, 0, BannerImageType.DESKTOP)
      );
      const defaultTabletImage = getPreviewValue(
        getImagePreviewName(name, 0, BannerImageType.TABLET)
      );
      const defaultMobileImage = getPreviewValue(
        getImagePreviewName(name, 0, BannerImageType.MOBILE)
      );

      if (defaultDesktopImage) {
        setPreviewValue(
          getImagePreviewName(name, tabIndex, BannerImageType.DESKTOP),
          defaultDesktopImage
        );
      }
      if (defaultTabletImage) {
        setPreviewValue(
          getImagePreviewName(name, tabIndex, BannerImageType.TABLET),
          defaultTabletImage
        );
      }
      if (defaultMobileImage) {
        setPreviewValue(
          getImagePreviewName(name, tabIndex, BannerImageType.MOBILE),
          defaultMobileImage
        );
      }
    },
    [name, getPreviewValue, setPreviewValue]
  );

  useWatch({ name });

  const onRemove = useCallback(
    index => {
      const values = getValues(`${name}[${index}]`);

      const { lang, country } = values.locale;

      console.log('set', `${lang}-${country || ''}`, values);
      if (lang) {
        cachedLocales.current[`${lang}-${country || ''}`] = { ...values };
      }

      setPreviews({});

      remove(index);
    },
    [name, remove, getValues, setPreviews]
  );

  const extendFromCache = useCallback(
    index => {
      const values = getValues(`${name}[${index}]`);

      const { lang, country } = values.locale;
      if (lang) {
        const v = cachedLocales.current[`${lang}-${country || ''}`];
        if (v) {
          setValue(`${name}[${index}]`, { ...v });
          setPreviewValue(
            getImagePreviewName(name, index, BannerImageType.DESKTOP),
            null
          );
        }
      }
    },
    [setPreviewValue, getValues, setValue, name]
  );

  return (
    <Tabs
      activeTab={activeTab}
      setActiveTab={handleTabChange}
      fullWidthTabs
      createButton
      onClickCreate={e => {
        handleTabChange(fields.length);

        const defaultValues = getValues(`${name}[0]`);

        append({
          ...defaultValues,
          locale: {},
        });

        if (defaultValues.images) handleNewImagePreviews(fields.length);
      }}
      tabs={fields.map((field, index) => {
        return {
          label: isDefaultLocale(field.locale) ? (
            <LocaleTabLabel label={'All Locales'}>All locales</LocaleTabLabel>
          ) : field.locale.lang ? (
            <LocaleTabLabel
              lang={
                isoLangs[field.locale.lang]
                  ? isoLangs[field.locale.lang].name
                  : field.locale.lang
              }
              country={
                field.locale.country &&
                field.locale.country !== 'all' &&
                (isoCountries[field.locale.country.toUpperCase()] ||
                  field.locale.country)
              }
            />
          ) : (
            <LocaleTabLabel label={'New Locale'} />
          ),
          key: field.locale.lang + field.locale.country,
          content: (
            <>
              <TabContent>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <FormField
                      name={`${name}.${index}.locale.lang`}
                      onChange={() => {
                        setValue(`${name}.${index}.locale.country`, null);
                        extendFromCache(index);
                      }}
                    >
                      <LocaleLangSelect />
                    </FormField>
                  </Grid>
                  <Grid item xs={4}>
                    <FormField
                      name={`${name}.${index}.locale.country`}
                      deps={{ lang: `${name}.${index}.locale.lang` }}
                      onChange={() => {
                        extendFromCache(index);
                      }}
                    >
                      {({ lang }) => (
                        <LocaleCountrySelect lang={lang} disabled={!lang} />
                      )}
                    </FormField>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    sx={{
                      textAlign: 'right',
                    }}
                  >
                    <Button
                      disabled={fields.length === 1}
                      variant={'text'}
                      color={'error'}
                      onClick={() => {
                        handleTabChange(Math.max(index - 1, 0));
                        onRemove(index);
                      }}
                    >
                      Remove
                    </Button>
                  </Grid>
                </Grid>
              </TabContent>

              {content(field, index)}
            </>
          ),
        };
      })}
    />
  );
};

export default LocaleTabs;
