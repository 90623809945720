import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import FancyTable from '../../../components/Table/FancyTable';
import TableHeaderRow from '../../../components/Table/TableHeaderRow';
import StringField from '../../../components/Fields/StringField/StringField';
import DateField from '../../../components/Fields/DateField/DateField';
import NotificationAlert, {
  AlertVariant,
} from '../../../components/Notifications/NotificationAlert';
import Tooltip from '../../../components/Tooltip/Tooltip';
import useTableColumnsReorder from '../../../hooks/useTableColumnsReorder';
import useTableColumnToggle from '../../../hooks/useTableColumnToggle';
import useTableGrid from '../../../hooks/useTableGrid';
import useTableColumns from '../../../hooks/useTableColumns';
import useTableRowsRender from '../../../hooks/useTableRowsRender';
import { useToasts } from '../../../providers/ToastsProvider';
import { ConfirmationPromptContext } from '../../../../providers/ConfirmationPromptProvider';
import EventManagementConfirmationTitle from './ConfirmationModal/EventManagementConfirmationTitle';
import EventManagementConfirmationMessage from './ConfirmationModal/EventManagementConfirmationMessage';
import { confirmationTypes } from './ConfirmationModal/constants';
import { getGroupItemAffiliation } from './helpers';
import { eventSourcesMapping } from '../utils';
import { useMergerAPI } from '../../../hooks/event-management';

const useStyles = makeStyles({
  unmergeButton: {
    fontWeight: 600,
    cursor: 'pointer',
  },
  invertedIndicator: {
    margin: 4,
    width: 12,
    height: 12,
    borderRadius: 6,
    background: '#8496AB',
    opacity: 0.5,
  },
  invertedIndicatorActive: {
    background: '#08CA6D',
    opacity: 1,
  },
});

const COLUMNS = {
  ID: 'Source Id',
  SCHEDULED: 'Event Scheduled',
  AFFILIATION: 'Affiliation',
  SOURCES: 'Sources',
  INVERTED: 'Inverted',
  UNMERGE: 'Unmerge',
};

const initialToggledColumns = [
  COLUMNS.ID,
  COLUMNS.SCHEDULED,
  COLUMNS.AFFILIATION,
  COLUMNS.SOURCES,
  COLUMNS.INVERTED,
  COLUMNS.UNMERGE,
];

const columnsOrderConfig = [
  COLUMNS.ID,
  COLUMNS.SCHEDULED,
  COLUMNS.AFFILIATION,
  COLUMNS.SOURCES,
  COLUMNS.INVERTED,
  COLUMNS.UNMERGE,
];

const columnsWidthConfig = {
  [COLUMNS.ID]: 1.75,
  [COLUMNS.SCHEDULED]: 1.25,
  [COLUMNS.AFFILIATION]: 4.5,
  [COLUMNS.SOURCES]: 0.5,
  [COLUMNS.INVERTED]: 0.5,
  [COLUMNS.UNMERGE]: 0.5,
};

const EventManagementGroupTable = ({ loading, data, refetchGroups }) => {
  const classes = useStyles();

  const { unmergeGroupItem } = useMergerAPI();
  const { showToast } = useToasts();
  const { openConfirmation, closeConfirmation } = useContext(
    ConfirmationPromptContext
  );

  const handleUnmergeButtonClick = useCallback(
    async itemData => {
      openConfirmation({
        buttonLabel: 'Confirm',
        title: (
          <EventManagementConfirmationTitle
            type={confirmationTypes.unmerge}
            onClearIconClick={closeConfirmation}
          />
        ),
        message: (
          <EventManagementConfirmationMessage
            type={confirmationTypes.unmerge}
            firstAffiliation={getGroupItemAffiliation(itemData)}
          />
        ),
        callback: async () => {
          try {
            await unmergeGroupItem({
              groupId: data.groupId,
              sportId: data.sport.id,
              provider: itemData.provider,
              providerId: itemData.desc.id,
            });

            refetchGroups();
          } catch (e) {
            showToast(
              <NotificationAlert variant={AlertVariant.ERROR}>
                {`An error occurred while unmerging group item: ${e.message}`}
              </NotificationAlert>
            );
          }
        },
      });
    },
    [
      unmergeGroupItem,
      data,
      showToast,
      openConfirmation,
      closeConfirmation,
      refetchGroups,
    ]
  );

  const [toggledColumns, toggleColumn] = useTableColumnToggle(
    initialToggledColumns
  );

  const [columnsOrder, onReorderColumns] =
    useTableColumnsReorder(columnsOrderConfig);

  const { template, changeColumnWidth } = useTableGrid({
    columns: columnsWidthConfig,
    order: columnsOrder,
    toggledColumns,
    initialColumnWidth: columnsWidthConfig,
    onChangeColumnsWidth: () => {},
  });

  const { availableColumns, visibleColumns } = useTableColumns({
    columns: [
      {
        label: COLUMNS.ID,
        key: COLUMNS.ID,
        render: data => {
          return <StringField copy>{data.desc.id}</StringField>;
        },
        resizable: true,
      },
      {
        label: COLUMNS.SCHEDULED,
        key: COLUMNS.SCHEDULED,
        render: data => {
          return (
            <StringField>
              <DateField date={data.scheduled} />
            </StringField>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.AFFILIATION,
        key: COLUMNS.AFFILIATION,
        render: data => {
          const affiliation = getGroupItemAffiliation(data);

          return <StringField>{affiliation}</StringField>;
        },
        resizable: true,
      },
      {
        label: COLUMNS.SOURCES,
        key: COLUMNS.SOURCES,
        render: data => {
          return (
            <Tooltip title={data.parsers.join(', ')}>
              <StringField>
                {eventSourcesMapping[data.provider] || data.provider}
              </StringField>
            </Tooltip>
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.INVERTED,
        key: COLUMNS.INVERTED,
        render: data => {
          return (
            <div
              className={clsx(classes.invertedIndicator, {
                [classes.invertedIndicatorActive]: data.inverted,
              })}
            />
          );
        },
        resizable: true,
      },
      {
        label: COLUMNS.UNMERGE,
        isLabelHidden: true,
        key: COLUMNS.UNMERGE,
        render: data => {
          return (
            data.unmergable && (
              <StringField
                className={classes.unmergeButton}
                color="red"
                onClick={() => handleUnmergeButtonClick(data)}
              >
                Unmerge
              </StringField>
            )
          );
        },
        resizable: true,
      },
    ],
    order: columnsOrder,
    toggledColumns,
  });

  const renderRow = useTableRowsRender({
    availableColumns,
    visibleColumns,
    template,
  });

  return (
    <FancyTable
      loading={loading}
      data={data.groupItems}
      renderRow={renderRow}
      renderHeaderRow={
        <TableHeaderRow
          toggledColumns={toggledColumns}
          template={template}
          onReorder={onReorderColumns}
          availableColumns={availableColumns}
          visibleColumns={visibleColumns}
          toggleColumn={toggleColumn}
          onChangeColumnWidth={changeColumnWidth}
        />
      }
      rowHeight={38}
    />
  );
};

EventManagementGroupTable.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
  refetchGroups: PropTypes.func,
};

export default EventManagementGroupTable;
