import { getOrderedKeys } from '../../utils/schemaHelper';
import get from 'lodash/get';
import omit from 'lodash/omit';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';

import {
  DataTypeEnum,
  TableCellAlignByDataType,
  TableCellAlignByFieldType,
  TableCellAlignEnum,
} from '../../constants/appConstants';
import { NESTED_COMPONENT_TYPE_ENUM } from './NestedTableComponent';
import { compileConditions } from '../Field/FieldUtils';

export const isHideEmptyColumn = (uiSchema, columnsUiSchema, columnName) => {
  const hideEmptyColumn = get(
    columnsUiSchema,
    `${columnName}.ui:options.hide_empty`
  );
  const hideEmptyColumnTable = get(uiSchema, 'ui:options.hide_empty');

  return typeof hideEmptyColumn === 'undefined'
    ? hideEmptyColumnTable
    : hideEmptyColumn;
};

export const getVisibleFields = (fields, uiSchema, entity) => {
  const { columnsUiSchema } = getColumnsUiSchema(uiSchema);

  return fields.filter(fieldName => {
    const hideEmpty = isHideEmptyColumn(uiSchema, columnsUiSchema, fieldName);

    return (
      get(columnsUiSchema, `${fieldName}.ui:widget`) !== 'hidden' &&
      get(columnsUiSchema, `${fieldName}.toggled`) !== false &&
      (!entity || !hideEmpty || fieldName in entity)
    );
  });
};

export const getRows = ({ items, values, labels_x, labels_y }) => {
  return (
    items ||
    (values
      ? values.map((row, rowIndex) =>
          labels_y.reduce(
            (res, label, cellIndex) => {
              res[label] = row[cellIndex];
              return res;
            },
            {
              xy: labels_x[rowIndex],
            }
          )
        )
      : [])
  );
};

export const MapSchemaUiSchemaPath = {
  'properties.items.items.properties': 'items.items',
  'items.properties': 'items',
  properties: '',
};

export const getUiOrderPath = (uiSchema, columnsSchemaPath) => {
  const paths = ['items.items.ui:order', 'ui:order'];

  const path = paths.find(path => has(uiSchema, path));

  if (!path) {
    return [MapSchemaUiSchemaPath[columnsSchemaPath], 'ui:order']
      .filter(item => !!item)
      .join('.');
  }

  return path;
};

export const getColumnsSchemaPath = schema => {
  const paths = [
    'properties.items.items.properties',
    'items.properties',
    'properties',
  ];

  return paths.find(path => has(schema, path));
};

export const getColumnsUiSchemaPath = uiSchema => {
  const paths = ['items.items', 'items', ''];

  return paths.find(path => isEmpty(path) || has(uiSchema, path));
};

export const getColumnsOrder = uiSchema => {
  const path = getUiOrderPath(uiSchema);

  return {
    columnsOrder: get(uiSchema, path),
    path,
  };
};

export const getColumnsSchema = schema => {
  const path = getColumnsSchemaPath(schema);

  return {
    columnsSchema: get(schema, path, []),
    path,
  };
};

export const getColumnsUiSchema = uiSchema => {
  const path = getColumnsUiSchemaPath(uiSchema);

  return {
    columnsUiSchema: path ? get(uiSchema, path, []) : uiSchema,
    path,
  };
};

export const getTableColumns = (schema, uiSchema, entity, data) => {
  if (
    schema.properties &&
    schema.properties.labels_x &&
    schema.properties.labels_y &&
    schema.properties.values
  ) {
    return data && data.labels_x
      ? [
          { name: 'xy' },
          ...data.labels_y.map(label => ({
            name: label,
            title: label,
            type: get(schema, 'properties.values.items.items.type'),
          })),
        ]
      : [];
  }

  if (schema.type === 'array' && schema.items.type !== 'object') {
    return [
      {
        schema: schema.items,
      },
    ];
  }

  const { columnsSchema } = getColumnsSchema(schema);
  const { columnsUiSchema } = getColumnsUiSchema(uiSchema);
  const { columnsOrder } = getColumnsOrder(uiSchema);

  const columnsKeys = Object.keys(columnsSchema);

  const orderedKeys = getVisibleFields(
    [...getOrderedKeys(columnsKeys, columnsOrder)],
    uiSchema,
    entity
  );

  return orderedKeys
    .map(columnKey => {
      const columnSchema = columnsSchema[columnKey];
      const columnUiSchema = get(columnsUiSchema, columnKey, {});

      if (
        columnSchema &&
        columnSchema.type &&
        get(columnUiSchema, 'ui:options.label.template')
      ) {
        columnSchema.type = DataTypeEnum.STRING;
      }

      const column = {
        name: columnKey,
        schema: columnSchema,
        uiSchema: columnUiSchema,
        css: getColumnStyles(columnUiSchema),
        title: getColumnTitle(columnSchema, columnUiSchema) || columnKey,
        align: getColumnAlign(
          columnSchema && columnSchema.type,
          get(columnUiSchema, 'ui:options.field_type')
        ),
      };

      if (columnSchema && columnSchema.type === 'object') {
        return null;
      }
      if (columnSchema && columnSchema.type === 'array') {
        if (
          columnUiSchema &&
          columnUiSchema['ui:component'] === 'InlineTableDropdownList'
        ) {
          column.inlineTableDropdown = [
            ...getTableColumns(columnSchema, columnUiSchema, entity),
          ];
        } else {
          return null;
        }
      }

      return column;
    })
    .filter(column => {
      return column && column.schema;
    });
};

export const getColumnTitle = (schema, uiSchema) => {
  return (uiSchema && uiSchema.title) || (schema && schema.title);
};

export const getColumnStyles = uiSchema => {
  const styles = {};

  const width = get(uiSchema, 'ui:options.width');
  const cellBackground = get(uiSchema, 'ui:options.cell_background');

  if (width) {
    styles.minWidth = width;
    styles.maxWidth = width;
  }

  if (cellBackground) {
    styles.paddingLeft = 20;
  }

  return styles;
};

export const getColumnAlign = (dataType = DataTypeEnum.STRING, fieldType) => {
  if (fieldType && TableCellAlignByFieldType[fieldType]) {
    return TableCellAlignByFieldType[fieldType];
  }

  return TableCellAlignByDataType[dataType] || TableCellAlignEnum.LEFT;
};

export const getTableRowDecorators = (uiSchema, data) => {
  const decorators = get(uiSchema, 'ui:options.rows', {});
  const borderColor = decorators.background_color;

  return indexes => {
    if (borderColor) {
      return {
        borderColor: borderColor.conditions
          ? compileConditions(borderColor.conditions, data, indexes)
          : borderColor,
      };
    }

    return {};
  };
};

export const getTableNested = (schema, uiSchema, links) => {
  const columnsSchema = get(schema, 'items.properties', []);

  const columnsOrder = get(uiSchema, 'ui:order');

  const columnsKeys = Object.keys(columnsSchema);

  const orderedKeys = getOrderedKeys(columnsKeys, columnsOrder);

  const nested = [];

  for (let columnKey of orderedKeys) {
    const columnSchema = columnsSchema[columnKey];
    const columnUiSchema = get(
      uiSchema,
      `items.items.${columnKey}`,
      get(uiSchema, `items.${columnKey}`, {})
    );

    if (
      !columnSchema ||
      !columnUiSchema ||
      columnUiSchema['ui:widget'] === 'hidden' ||
      columnUiSchema['toggled'] === false
    ) {
      continue;
    }

    if (
      columnUiSchema['ui:component'] !== 'InlineTableDropdownList' &&
      (columnSchema.type === 'array' || columnSchema.type === 'object')
    ) {
      nested.push({
        title: columnKey,
        label: columnSchema.title || columnKey,
        schema: columnSchema,
        uiSchema: columnUiSchema,
        type:
          columnSchema.type === 'array'
            ? NESTED_COMPONENT_TYPE_ENUM.TABLE
            : NESTED_COMPONENT_TYPE_ENUM.FIELD_LIST,
      });
    }
  }

  const expandedLink =
    links[Object.keys(links).find(key => links[key]['x-expanded'])];

  if (expandedLink) {
    nested.push({
      link: expandedLink,
      title: 'Link',
      type: NESTED_COMPONENT_TYPE_ENUM.DYNAMIC,
    });
  }

  return nested;
};

export const getByIndexes = (data, indexes) => {
  if (Array.isArray(data) && 'nested' in indexes) {
    return data[indexes.nested]; // TODO fix fallback
  }

  for (let propertyKey in data) {
    if (indexes.hasOwnProperty(propertyKey)) {
      return getByIndexes(
        get(data, `${propertyKey}[${indexes[propertyKey]}]`),
        omit(indexes, propertyKey)
      );
    }
  }

  return data;
};

export const getVirtualizedColumns = columns => {
  return columns.map(column => {
    return {
      ...column,
      dataKey: column.name,
      label: column.title,
      width: 300,
    };
  });
};
