import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import get from 'lodash/get';
import { styled } from '@mui/system';
import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import useNavigateWithFrom from '../../../../hooks/useNavigateWithFrom';
import BreadCrumbs from '../../../components/BreadCrumbs/BreadCrumbs';
import Card from '../../../components/Card/Card';
import CopyField from '../../../components/Fields/CopyField/CopyField';
import CurrencyField from '../../../components/Fields/CurrencyField/CurrencyField';
import DateField from '../../../components/Fields/DateField/DateField';
import GroupSwitcherField from '../../../components/Fields/GroupSwitcherField/GroupSwitcherField';
import LabeledField from '../../../components/Fields/LabeledField/LabeledField';
import LinkField from '../../../components/Fields/LinkField/LinkField';
import StringField from '../../../components/Fields/StringField/StringField';
import FieldsGroup from '../../../components/FieldsGroup/FieldsGroup';
import NotificationAlert, {
  AlertVariant,
} from '../../../components/Notifications/NotificationAlert';
import Page from '../../../components/Page/Page';
import TabLabel from '../../../components/Tabs/TabLabel';
import Tabs from '../../../components/Tabs/Tabs';
import getEventDetails from '../../../gql/Reports/queries/getEventDetails';
import getOperatorEventDetails from '../../../gql/Reports/queries/getOperatorEventDetails';
import changeEventAvailability from '../../../gql/mutations/changeEventAvailability';
import useBreadcrumbs from '../../../hooks/useBreadcrumbs';
import {
  useIsAdmin,
  useIsOperator,
  useIsRMAllowed,
} from '../../../providers/OperatorProvider';
import { useToasts } from '../../../providers/ToastsProvider';
import NotFoundPage from '../../NotFoundPage/NotFoundPage';
import TournamentsConfigTable from '../../TournamentsPage/TournamentConfigTable';
import EventBetHistoryTab from './components/EventHistory/EventBetHistoryTab';
import EventPlayersTab from './components/EventPlayers/EventPlayersTab';
import GamingActivityTab from './components/GamingActivity/GamingActivityTab';
import LiabilityMatrixTab from './components/LiabilityMatrix/LiabilityMatrixTab';
import MarketLiabilityTab from './components/MarketLiability/MarketLiabilityTab';

const SourcesIdsField = styled(LabeledField)`
  grid-column: span 2;
`;

const EventDetailsTabContent = styled(Box)`
  background-color: #f7f7f7;
  border-radius: 4px;
`;

const Cards = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const TournamentConfigContainer = styled('div')`
  background-color: #f7f7f7;
  padding: 8px;
  border-radius: 8px 8px 3px 3px;
`;

const EventRiskManagementCard = styled(Card)`
  padding: 16px 2px 2px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const EventDetails = () => {
  const { eventId, tabKey } = useParams();
  const isOperator = useIsOperator();
  const isRMAllowed = useIsRMAllowed();
  const isAdmin = useIsAdmin();

  const {
    data: { data } = {},
    loading,
    error,
  } = useQuery(
    isOperator || !isRMAllowed ? getOperatorEventDetails : getEventDetails,
    {
      variables: {
        eventId: eventId,
      },
    }
  );

  const client = useApolloClient();

  const sportId = get(data, 'sport.id');
  const { showToast } = useToasts();
  const [offeredLoading, setOfferedLoading] = useState(false);
  const [changeOffered] = useMutation(changeEventAvailability);

  const setOffered = useCallback(
    offered => {
      setOfferedLoading(true);
      changeOffered({ variables: { eventId, availability: offered } })
        .catch(() => {
          showToast(
            <NotificationAlert variant={AlertVariant.ERROR}>
              An error occurred while setting offered/not offered for event
            </NotificationAlert>
          );
        })
        .finally(() => {
          client
            .refetchQueries({
              include: [getEventDetails],
            })
            .finally(() => {
              setOfferedLoading(false);
            });
        });
    },
    [showToast, client, eventId, changeOffered]
  );

  const crumbs = useBreadcrumbs([
    {
      label:
        data &&
        `${data.sport.name} - ${data.category.name} - ${data.tournament.name} - ${data.event}`,
    },
  ]);

  const navigate = useNavigateWithFrom();

  if (error) {
    return <ErrorWidget />;
  }

  return data ? (
    <Page title={'Event Details'}>
      <BreadCrumbs crumbs={crumbs} />

      <Cards>
        <FieldsGroup>
          <LabeledField label={'Event Date'}>
            <DateField
              date={data.eventScheduled}
              format={'YYYY/MM/DD HH:mm:ss'}
            />
          </LabeledField>

          <LabeledField label={'Event ID'}>
            <CopyField value={data.id}>
              <StringField>{data.id}</StringField>
            </CopyField>
          </LabeledField>

          {!isOperator && isAdmin && isRMAllowed && (
            <div>
              <GroupSwitcherField
                value={data.offered}
                onChange={setOffered}
                disabled={offeredLoading}
                options={[
                  {
                    label: 'Offered',
                    activeColor: 'green',
                    value: true,
                  },
                  {
                    label: 'Not Offered',
                    activeColor: 'red',
                    value: false,
                  },
                ]}
              />
            </div>
          )}
          {!isOperator && isAdmin && isRMAllowed && (
            <SourcesIdsField label={'Event available sources with IDs'}>
              <StringField wrap>
                {data.providers ? (
                  data.providers
                    .map(provider => `${provider.id} (${provider.name})`)
                    .join(', ')
                ) : (
                  <EmptyField />
                )}
              </StringField>
            </SourcesIdsField>
          )}

          {!isOperator && isAdmin && isRMAllowed && (
            <LinkField
              href={data.sport && `/event-management`}
              large
              target={'_blank'}
            >
              Event management
            </LinkField>
          )}
        </FieldsGroup>

        <FieldsGroup>
          <LabeledField label={'GGR Settled'}>
            <CurrencyField currency={'EUR'} amount={data.ggr} />
          </LabeledField>

          <LabeledField label={'AVG Bet'}>
            <CurrencyField currency={'EUR'} amount={data.avgBet} />
          </LabeledField>

          <LabeledField label={'Max Bet'}>
            <CurrencyField currency={'EUR'} amount={data.maxBet} />
          </LabeledField>

          {/*<LabeledField label={'Total Active Markets'}>*/}
          {/*  <StringField>{data.totalActiveMarkets}</StringField>*/}
          {/*</LabeledField>*/}

          <LabeledField label={'Players'}>
            <StringField>{data.players}</StringField>
          </LabeledField>

          <LabeledField label={'Bets'}>
            <StringField>{data.bets}</StringField>
          </LabeledField>

          <LabeledField label={'Turnover'}>
            <CurrencyField currency={'EUR'} amount={data.turnover} />
          </LabeledField>

          <LabeledField label={'Turnover Open'}>
            <CurrencyField currency={'EUR'} amount={data.turnoverOpen} />
          </LabeledField>

          <LabeledField label={'First Bet Date'}>
            <DateField
              date={data.firstBetDate}
              format={'YYYY/MM/DD HH:mm:ss'}
            />
          </LabeledField>

          <LabeledField label={'Last Bet Date'}>
            <DateField date={data.lastBetDate} format={'YYYY/MM/DD HH:mm:ss'} />
          </LabeledField>

          <LabeledField label={'MAX GGR'}>
            <CurrencyField currency={'EUR'} amount={data.maxGgrEur} />
          </LabeledField>

          <LabeledField label={'MIN GGR'}>
            <CurrencyField currency={'EUR'} amount={data.minGgrEur} />
          </LabeledField>

          <LabeledField label={'BETS (FREEBET)'}>
            <StringField>{data.freebetBets}</StringField>
          </LabeledField>

          <LabeledField label={'PLAYERS (FREEBET)'}>
            <StringField>{data.freebetPlayers}</StringField>
          </LabeledField>

          <LabeledField label={'TURNOVER (FREEBET)'}>
            <CurrencyField currency={'EUR'} amount={data.freebetTurnover} />
          </LabeledField>

          <LabeledField label={'GGR (FREEBET)'}>
            <CurrencyField currency={'EUR'} amount={data.freebetGgr} />
          </LabeledField>
        </FieldsGroup>
        {!isOperator && isAdmin && isRMAllowed && (
          <EventRiskManagementCard>
            <Box
              sx={{
                ml: '26px',
              }}
            >
              <LinkField
                href={`/related-contingencies?filters={"events_ids":["${eventId}"]}`}
                large
              >
                Related Contingencies
              </LinkField>
            </Box>

            <TournamentConfigContainer>
              <TournamentsConfigTable tournamentId={data.tournament.id} />
            </TournamentConfigContainer>
          </EventRiskManagementCard>
        )}

        <Box
          sx={{
            mt: 2,
          }}
        >
          <Tabs
            activeTabKey={tabKey || 'players'}
            fullWidthTabs
            onChangeTab={tab => {
              navigate(`/events/${eventId}/${tab}`);
            }}
            tabs={[
              {
                key: 'players',
                label: <TabLabel>Event Players</TabLabel>,
                content: (
                  <EventDetailsTabContent key={'event-players'}>
                    <EventPlayersTab eventId={eventId} />
                  </EventDetailsTabContent>
                ),
              },
              {
                key: 'bet-history',
                label: <TabLabel>Event Bet History</TabLabel>,
                content: (
                  <EventDetailsTabContent key={'bet-history'}>
                    <EventBetHistoryTab />
                  </EventDetailsTabContent>
                ),
              },
              {
                key: 'gaming-activity',
                label: <TabLabel>Gaming Activity</TabLabel>,
                content: (
                  <EventDetailsTabContent key={'gaming-activity'}>
                    <GamingActivityTab eventId={eventId} event={data} />
                  </EventDetailsTabContent>
                ),
              },
              // soccer only
              ...(sportId === '1'
                ? [
                    {
                      key: 'liability-matrix',
                      label: <TabLabel>Liability Matrix</TabLabel>,
                      content: (
                        <EventDetailsTabContent key="liability-matrix">
                          <LiabilityMatrixTab event={data} />
                        </EventDetailsTabContent>
                      ),
                    },
                  ]
                : []),
              {
                key: 'market-liability',
                label: <TabLabel>Market Liability</TabLabel>,
                content: (
                  <EventDetailsTabContent key={'market-liability'}>
                    {/* <PresetsProvider
                      operationId={'ReporterAPI.get_market_liability_v2'}
                    >
                      <MarketLiabilityTab />
                    </PresetsProvider> */}
                    <MarketLiabilityTab event={data} />
                  </EventDetailsTabContent>
                ),
              },
            ]}
          />
        </Box>
      </Cards>
    </Page>
  ) : loading ? null : (
    <NotFoundPage />
  );
};

export default EventDetails;
