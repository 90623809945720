import makeStyles from '@mui/styles/makeStyles';
import MenuItem from '@mui/material/MenuItem';
import React, { memo, useCallback } from 'react';
import Menu from '@mui/material/Menu/Menu';
import clsx from 'clsx';
import MoreVertIcon from '../../icons/MoreVertIcon.svg';

const useStyles = makeStyles(theme => ({
  destructiveMenuItem: {
    color: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.error.main,
      background: '#FFE5E7',
    },
  },

  rowActionsButtonRoot: {
    margin: 0,
    backgroundColor: 'transparent',
    border: 'none',
    padding: 5,
    cursor: 'pointer',
    transition: 'color 0.3s ease-in-out',
    color: '#031224',
    display: 'flex',
    width: 20,
    justifyContent: 'center',
    '&:hover': {
      opacity: 0.5,
    },
  },
  rowActionsButtonIcon: {
    height: 10,
    width: 2,
    backgroundImage: `url(${MoreVertIcon})`,
    backgroundRepeat: 'no-repeat',
  },
}));

const TableRowActions = ({ row, rowActions }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState();
  const isMenuOpen = Boolean(anchorEl);

  const handleMenuClick = useCallback(event => {
    if (event.currentTarget) {
      // @ts-ignore
      setAnchorEl(event.currentTarget);
    }
  }, []);

  const handleMenuClose = useCallback(() => {
    // @ts-ignore
    setAnchorEl(null);
  }, []);

  return (
    <>
      <div>
        <button
          className={classes.rowActionsButtonRoot}
          onClick={handleMenuClick}
          data-cy={'table-row-actions'}
        >
          <div className={classes.rowActionsButtonIcon} />
        </button>
      </div>

      {isMenuOpen && (
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          keepMounted={false}
          open={isMenuOpen}
          onClose={handleMenuClose}
          data-cy={'table-row-actions-menu'}
          PaperProps={{
            style: {
              minWidth: 200,
              borderRadius: 8,
              boxShadow:
                '0px 8px 32px rgba(132, 150, 171, 0.16), 0px 2px 16px rgba(132, 150, 171, 0.25)',
            },
          }}
        >
          {rowActions
            .map(action =>
              typeof action === 'function' ? action(row) : action
            )
            .map(action => (
              <MenuItem
                data-cy={'table-row-action'}
                disabled={action.disabled && action.disabled(row)}
                classes={{
                  root: clsx({
                    [classes.destructiveMenuItem]: action.destructive,
                  }),
                }}
                key={action.key}
                onClick={async () => {
                  action.onClick && action.onClick(row);

                  handleMenuClose();
                }}
              >
                {action.label}
              </MenuItem>
            ))}
        </Menu>
      )}
    </>
  );
};

export default memo(TableRowActions);
