import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';
import Grid from '@mui/material/Grid';
import Switch from '../../../components/Inputs/Switch/Switch';
import TextField from '../../../components/Inputs/TextField';
import SportIcon from '../../../icons/SportIcon/SportIcon';
import VirtualizedList from '../../../components/List/VirtualizedList';

const useStyles = makeStyles({
  marketsConfigPanel: {
    height: 'calc(100vh - 120px)',
  },
  panelListWrap: {
    height: '100%',
    overflow: 'hidden',
  },
  panelList: {
    borderRadius: 8,
    backgroundColor: '#fff',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '6px',
      background: '#fff',
      padding: '1px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '6px',
      background: 'rgba(132, 150, 171, 0.4)',
    },
  },
  sportItem: {
    padding: '12px 8px',
    display: 'flex',
    alignItems: 'center',
    '&:hover:not($sportItemActive)': {
      cursor: 'pointer',
      backgroundColor: 'rgba(21, 129, 255, 0.1)',
    },
  },
  sportItemActive: {
    backgroundColor: 'rgba(21, 129, 255, 0.3)',
  },
  sportIcon: {
    marginRight: 8,
    backgroundSize: 16,
    minWidth: 18,
  },
  marketItem: {
    padding: '8px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const MarketsConfigPanel = ({
  sports,
  selectedSportId,
  setSelectedSportId,
  onSportSearchChange,
  markets,
  onMarketToggle,
  onMarketSearchChange,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      rowSpacing={{ xs: 1 }}
      columnSpacing={{ xs: 2 }}
      className={classes.marketsConfigPanel}
    >
      <Grid item xs={4}>
        <TextField
          label={'Sport name'}
          fullWidth
          margin={'dense'}
          onChange={e => onSportSearchChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          label={'Market name'}
          fullWidth
          margin={'dense'}
          onChange={e => onMarketSearchChange(e.target.value)}
        />
      </Grid>
      <Grid item xs={4} className={classes.panelListWrap}>
        <VirtualizedList
          className={classes.panelList}
          items={sports}
          renderItem={sport => (
            <div
              key={sport.id}
              className={clsx(classes.sportItem, {
                [classes.sportItemActive]: sport.id === selectedSportId,
              })}
              onClick={() => setSelectedSportId(sport.id)}
            >
              <SportIcon name={sport.id} className={classes.sportIcon} black />
              {sport.name}
            </div>
          )}
        />
      </Grid>
      <Grid item xs={8} className={classes.panelListWrap}>
        <VirtualizedList
          className={classes.panelList}
          items={markets}
          renderItem={market => (
            <div
              key={market.id}
              className={classes.marketItem}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              {market.name}
              <Switch
                onChange={v => onMarketToggle(v, market.id)}
                value={market.enabled}
              />
            </div>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default MarketsConfigPanel;
