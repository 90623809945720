import React, { useContext, useRef, useState } from 'react';
import { DataContext } from '../../providers/DataProvider';
import { SchemaContext } from '../../providers/SchemaProvider';

import {
  getRows,
  getTableColumns,
  getTableNested,
  getTableRowDecorators,
} from './TableUtils';
import get from 'lodash/get';
import { Table as MuiTable } from '@mui/material';
import TableBody from './TableBody';
import TableRowsDataProvider from '../../providers/TableRowsDataProvider';
import { LinksContext } from '../../providers/LinksProvider';
import TableBodyPlaceholder from './TableBodyPlaceholder';
import { isPathSortable } from '../../utils/schemaHelper';
import { RoutesContext } from '../../providers/RoutesProvider';
import TablePagination from './TablePagination';
import makeStyles from '@mui/styles/makeStyles';
import TableRowActionsProvider from '../../providers/TableRowActionsProvider';
import ContainerBoundsProvider from '../../providers/ContainerWidthProvider';
import ChartList from './ChartList';
import RowDecoratorsProvider from '../../providers/RowDecoratorsProvider';
import { getTableActions } from './TableActionsUtils';
import { StaticFieldListContext } from '../../providers/StaticFieldListProvider';
import TableProvider from '../../providers/TableProvider';
import classNames from 'clsx';
import ActionCards from '../../components/ActionCards';
import StickyHeaderProvider from '../../providers/StickyHeaderProvider';
import { ScrollSync } from 'react-scroll-sync';
import 'simplebar/dist/simplebar.min.css';
import Scrollbar from '../../components/Scrollbar/Scrollbar';
import { useUiComponentSchema } from '../../providers/UiComponentSchemaProvider';
import StickyTableHead from './StickyTableHead';
import { useIsNestedTable } from '../../providers/NestedTableProvider';

const useStyles = makeStyles(() => ({
  tableContainer: {
    display: 'block',
    // height: 400,
    // overflow: 'auto',
    width: '100%',
  },

  tableContainerEmpty: {
    overflow: 'hidden',

    '& $tableRoot': {
      display: 'block',
      width: '100%',
    },
  },

  tableRoot: {},

  tableRootNested: {
    padding: 8,
  },

  tableRootPreventOverflow: {
    paddingBottom: '400px',
    marginBottom: '-400px',
  },
}));

const Table = ({ nestedKey }) => {
  const classes = useStyles();

  const { path, data, loading, firstSkipped } = useContext(DataContext);

  const uiSchema = useUiComponentSchema();

  const schema = useContext(SchemaContext);
  const routes = useContext(RoutesContext);

  const rows = getRows(
    (Array.isArray(data)
      ? {
          items: data,
        }
      : data) || {}
  );

  const isLoading = loading || !data;

  const propertiesSchema = get(schema, 'properties.items', schema);

  const links = useContext(LinksContext);

  const columns = getTableColumns(propertiesSchema, uiSchema, rows[0], data);

  const nested = getTableNested(propertiesSchema, uiSchema, links);

  let { actions, rowActions } = getTableActions(
    links,
    get(uiSchema, 'ui:options.actions')
  );

  rowActions = rowActions.filter(action => {
    return (
      !nested.find(
        item =>
          item.link &&
          item.link.operationId &&
          item.link.operationId === action.link.operationId
      ) &&
      !columns.find(
        item =>
          item.uiSchema &&
          item.uiSchema['ui:options'] &&
          item.uiSchema['ui:options'].editLink === action.link.operationId
      )
    );
  });

  const isSortable = isPathSortable(routes[path]);

  const inStaticFieldList = useContext(StaticFieldListContext);

  const getRowDecoratorsByIndexes = getTableRowDecorators(uiSchema, data);
  const isEmpty = (!rows.length && !isLoading) || firstSkipped;

  const scrollableNodeRef = useRef();

  const tableContainerRef = useRef();

  const isNestedTable = useIsNestedTable();

  const [isScrollDisabled, setIsScrollDisabled] = useState(false);

  return (
    <>
      <ScrollSync vertical={false}>
        <StickyHeaderProvider>
          {!isNestedTable && <ActionCards path={path} actions={actions} />}

          <div
            ref={tableContainerRef}
            className={classNames(classes.tableContainer, {
              [classes.tableContainerEmpty]: isEmpty,
            })}
          >
            {get(uiSchema, 'ui:options.charts') && (
              <ChartList
                defaultExpanded={!!get(uiSchema, 'ui:options.show_charts')}
                charts={get(uiSchema, 'ui:options.charts')}
                data={data && data.items}
              />
            )}

            <ContainerBoundsProvider containerRef={tableContainerRef}>
              <Scrollbar
                scrollableNodeRef={scrollableNodeRef}
                disabled={isScrollDisabled}
              >
                {/*<VirtualizedTable*/}
                {/*  rowCount={rows.length}*/}
                {/*  columns={getVirtualizedColumns(columns)}*/}
                {/*/>*/}
                <MuiTable
                  stickyHeader={true}
                  classes={{
                    root: classNames(classes.tableRoot, {
                      [classes.tableRootNested]: inStaticFieldList,
                    }),
                  }}
                >
                  <TableProvider>
                    <TableRowActionsProvider value={rowActions}>
                      <StickyTableHead
                        isLoading={isLoading}
                        scrollableRef={scrollableNodeRef}
                        entity={rows[0]}
                        disabled={
                          isNestedTable ||
                          isLoading ||
                          isEmpty ||
                          rows.length < 5
                        }
                        isEmpty={isEmpty}
                        columns={columns}
                        hasNested={!!nested.length}
                        isSortable={isSortable}
                      />

                      <TableRowsDataProvider rows={rows}>
                        <RowDecoratorsProvider
                          value={getRowDecoratorsByIndexes}
                        >
                          <TableBody
                            nestedKey={nestedKey}
                            firstSkipped={firstSkipped}
                            columns={columns}
                            nested={nested}
                            isEmpty={isEmpty}
                            setIsScrollDisabled={setIsScrollDisabled}
                          />
                        </RowDecoratorsProvider>
                        {isLoading && !firstSkipped && <TableBodyPlaceholder />}
                      </TableRowsDataProvider>
                    </TableRowActionsProvider>
                  </TableProvider>
                </MuiTable>
              </Scrollbar>
            </ContainerBoundsProvider>
          </div>
          <TablePagination />
        </StickyHeaderProvider>
      </ScrollSync>
    </>
  );
};

export default Table;
