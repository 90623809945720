import DateField from '../../../../components/Fields/DateField/DateField';
import EmptyField from '../../../../components/Fields/EmptyField/EmptyField';
import StringField from '../../../../components/Fields/StringField/StringField';
import { MarketProviderNames } from '../../../../components/Inputs/TreeSelect/TreeSelectTypes/Sources/SourcesTreeSelect';
import Table from '../../../../components/Table/Table';
import useTableColumnsReorder from '../../../../hooks/useTableColumnsReorder';

export const COLUMN = {
  sports: 'sports',
  source: 'source',
  status: 'status',
  user: 'user',
  last_updated: 'last_updated',
};

export const columnsWidthConfig = {
  [COLUMN.sports]: 2,
  [COLUMN.source]: 2,
  [COLUMN.status]: 1,
  [COLUMN.user]: 1,
  [COLUMN.last_updated]: 1,
};

export const columnsMinWidthConfig = {};

export const columnsOrderConfig = [
  COLUMN.sports,
  COLUMN.source,
  COLUMN.status,
  COLUMN.user,
  COLUMN.last_updated,
];

export const initialToggledColumns = [
  COLUMN.sports,
  COLUMN.source,
  COLUMN.status,
  COLUMN.user,
  COLUMN.last_updated,
];

const SourcesTable = ({ data, loading, error }) => {
  const [order, onReorder] = useTableColumnsReorder(columnsOrderConfig);

  const columns = [
    {
      label: 'Sports',
      key: COLUMN.sports,
      render: ({ sport }) => {
        return sport.name ? (
          <StringField>{sport.name}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Status',
      key: COLUMN.status,
      render: ({ status }) => {
        return status ? <StringField>{status}</StringField> : <EmptyField />;
      },
    },
    {
      label: 'Source',
      key: COLUMN.source,
      render: ({ source }) => {
        const sourceTitle = MarketProviderNames[source] || source;

        return source ? (
          <StringField title={sourceTitle}>{sourceTitle}</StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'User',
      key: COLUMN.user,
      render: ({ user }) => {
        return user ? (
          <StringField title={user}>
            {/* <LinkField href={``}>{name}</LinkField>
             */}
            {user}
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
    {
      label: 'Last Update',
      key: COLUMN.last_updated,
      render: ({ lastUpdated }) => {
        return lastUpdated ? (
          <StringField>
            <DateField date={lastUpdated} format={'YYYY.MM.DD, HH:mm'} />
          </StringField>
        ) : (
          <EmptyField />
        );
      },
    },
  ];

  return (
    <Table
      initialToggledColumns={initialToggledColumns}
      loading={loading}
      data={data}
      order={order}
      onReorder={onReorder}
      columnsWidth={columnsWidthConfig}
      columns={columns}
      configMenu={false}
      error={error}
    />
  );
};

export default SourcesTable;
