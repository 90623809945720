import evalCondition from '../../utils/evalCondition';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import get from 'lodash/get';
import { formatCurrency } from '../../v2/components/Fields/CurrencyField/CurrencyField';

export const ComponentEnum = {
  STRING: 'string',
  BADGE: 'badge',
  LINK: 'link',
  BADGE_LINK: 'badgeLink',
  ICON: 'icon',
  BOOLEAN: 'boolean',
  BOOLEAN_INPUT: 'booleanInput',
  BOOLEAN_GROUP_INPUT: 'booleanGroupInput',
  CODE: 'code',
  IMAGE: 'image',
  COUNTDOWN: 'countdown',
  WATCH: 'watch',
  TEST_FIELD: 'TestField',
};

export const ValueTextFormatEnum = {
  CURRENCY: 'currency',
};

export const ValueTextFormatMap = {
  [ValueTextFormatEnum.CURRENCY]: (x, args = {}) => {
    return formatCurrency(x, args.currency_code, args.formatOptions);
  },
};

export const compileFieldSchema = (fieldSchema, data, indexes) => {
  return {
    actions: compileSchema(fieldSchema.actions, data, indexes),
    decorators: compileSchema(fieldSchema.decorators, data, indexes),
    value: compileSchema(fieldSchema.value, data, indexes),
  };
};

export const compileSchema = (schema, data, indexes, debug) => {
  let result = {};

  if (schema.conditions) {
    if (schema.component === ComponentEnum.COUNTDOWN) {
      result = {
        ...result,
        execConditions: data =>
          compileConditions(schema.conditions, data, indexes),
      };
    } else {
      result = {
        ...result,
        ...compileConditions(schema.conditions, data, indexes),
      };
    }

    schema = omit(schema, 'conditions');
  }

  if (schema.template && schema.data) {
    debug && console.log(schema, data, indexes);

    result = {
      ...result,
      text: compileTemplate(schema.template, schema.data, data, indexes, debug),
    };

    schema = omit(schema, ['template', 'data']);
  }

  if (schema.property) {
    result = compileTemplate('{{0}}', [schema.property], data, indexes);
    schema = omit(schema, ['property']);
  }

  for (let propertyKey in schema) {
    const property = schema[propertyKey];

    if (isPlainObject(property)) {
      result[propertyKey] = compileSchema(property, data, indexes, debug);
    } else if (Array.isArray(property)) {
      result[propertyKey] = property.map(item => {
        return compileSchema(item, data, indexes);
      });
    } else {
      result[propertyKey] = property;
    }
  }

  if (typeof result === 'object') {
    return {
      ...result,
    };
  }

  return result;
};

export const prepareFieldSchema = (key, value, uiSchema = {}, schema = {}) => {
  const uiOptions = uiSchema['ui:options'] || {};

  return {
    actions: prepareFieldActions(uiOptions),
    decorators: prepareFieldDecorators(uiOptions),
    value: prepareFieldValue(key, value, uiOptions, schema),
  };
};

export const prepareFieldValue = (key, fieldValue, uiOptions, schema) => {
  const {
    width,
    fontWeight,
    field_type,
    currency_code,
    conditions,
    link,
    linkParameters,
    external_link,
    tooltip,
    label,
    bold,
    icons,
    editLink,
    highlightLimit,
    openNewTab,
    formatOptions,
    ...otherOptions
  } = uiOptions;

  const { type: dataType } = schema;

  const value = {
    ...otherOptions,
  };

  if (width) {
    value.width = width;
  }

  if (fontWeight) {
    value.fontWeight = fontWeight;
  }

  if (field_type === 'badge' && link) {
    value.component = ComponentEnum.BADGE_LINK;
  } else if (field_type === 'badge') {
    value.component = ComponentEnum.BADGE;
  } else if (link || external_link) {
    value.component = ComponentEnum.LINK;
  } else if (field_type === 'icon') {
    value.component = ComponentEnum.ICON;
    value.icons = icons;
  } else if (field_type === 'code') {
    value.component = ComponentEnum.CODE;
  } else if (field_type === 'image') {
    value.component = ComponentEnum.IMAGE;
  } else if (field_type === 'counter') {
    value.component = ComponentEnum.COUNTDOWN;
  } else {
    value.component = ComponentEnum.STRING;
  }

  if (field_type === 'FieldType') {
    value.componentt = ComponentEnum.TEST_FIELD;
  }

  if (link) {
    value.link = {
      operationId: link,
      parameters: linkParameters,
    };
  }

  if (external_link) {
    value.externalLink = external_link;
  }

  if (editLink) {
    value.editLink = editLink;
  }

  if (conditions) {
    value.conditions = conditions;
  }

  value.data = fieldValue;

  if (tooltip) {
    value.tooltip = tooltip;
  }

  if (openNewTab) {
    value.openNewTab = openNewTab;
  }

  if (label) {
    value.label = label;
  } else {
    value.label = {
      template: `${fieldValue}`,
      data: [],
    };
  }

  if (bold) {
    value.label.fontWeight = 'bold';
  }

  if (highlightLimit) {
    value.label.highlightLimit = highlightLimit;
  }

  if (field_type === 'currency') {
    value.label.format = ValueTextFormatMap[ValueTextFormatEnum.CURRENCY];
    value.label.formatArgs = {
      currency_code,
      formatOptions,
    };
  }

  if (
    dataType === 'number' ||
    dataType === 'integer' ||
    field_type === 'number' ||
    field_type === 'integer' ||
    field_type === 'currency'
  ) {
    value.label.type = 'number';
  } else if (field_type === 'code') {
    value.label.type = 'code';
  }

  if (dataType === 'boolean') {
    value.component = ComponentEnum.BOOLEAN;

    if (editLink) {
      if (field_type === 'booleanGroup') {
        value.component = ComponentEnum.BOOLEAN_GROUP_INPUT;
      } else {
        value.component = ComponentEnum.BOOLEAN_INPUT;
      }
    }

    if (field_type === 'watch') {
      value.component = ComponentEnum.WATCH;
    }
  }

  value.key = key;

  return value;
};

export const preparePathCondition = (ifCondition, indexes = {}, global) => {
  let condition = ifCondition.replace(
    new RegExp('\\$response.body#/', 'g'),
    ''
  );

  condition = condition.replace(new RegExp('/', 'g'), '.');

  for (let indexKey in indexes) {
    condition = condition.replace(
      new RegExp(indexKey, global ? 'g' : ''),
      `${indexKey}[${indexes[indexKey]}]`
    );
  }

  return condition;
};

export const compileConditions = (conditionsSchema, data, indexes) => {
  let matchedProperties = {};

  for (let condition of conditionsSchema) {
    const { if: conditionIf, ...properties } = condition;

    if (!conditionIf) {
      matchedProperties = {
        ...matchedProperties,
        ...properties,
      };
    } else {
      const preparedCondition = preparePathCondition(
        conditionIf,
        indexes,
        global
      );

      const matched = evalCondition(preparedCondition, data);

      if (matched) {
        matchedProperties = {
          ...matchedProperties,
          ...properties,
        };
      }
    }
  }

  return matchedProperties;
};

export const compileTemplate = (
  template,
  templateData,
  data,
  indexes,
  debug
) => {
  for (let key in templateData) {
    const dataPath = templateData[key];

    if (dataPath) {
      const value = get(
        data,
        preparePathCondition(dataPath, indexes),
        get(data, `${indexes.nested}.${dataPath.split('/').pop()}`)
      ); // TODO fix fallback
      debug && console.log(data, dataPath.split('/').pop());
      template = template.replace(`{{${key}}}`, value);
    }
  }

  return template.trim();
};

export const prepareFieldDecorators = uiOptions => {
  const decorators = {};

  if (uiOptions.left_vertical_strip) {
    decorators.verticalStrip = uiOptions.left_vertical_strip;
  }

  if (uiOptions.cell_background) {
    decorators.cellBackground = uiOptions.cell_background;
  }

  if (uiOptions.show_percentage) {
    decorators.showPercentage = uiOptions.show_percentage;
  }

  return decorators;
};

export const prepareFieldActions = uiOptions => {
  const actions = {};

  if (uiOptions.copy) {
    actions.copy = uiOptions.copy;
  }

  return actions;
};
