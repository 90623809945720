export const mapProviderToHumanReadable = provider => {
  const rules = {
    betby_trading: 'BETBY trading',
    betgenius: 'BetGenius',
    betradar: 'BetRadar',
    dst: 'DST',
    lsport: 'LSports',
    oddin: 'Oddin',
    sportmarket: 'SportMarket',
    statscore: 'StatsCore',
    tradeart: 'TradeArt',
  };

  return rules[provider] || provider;
};
