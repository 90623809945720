import { makeStyles } from '@mui/styles';
import TableInlinePopup from '../../components/Table/TableInlinePopup';
import Button from '../../components/Button/Button';

const useStyles = makeStyles({
  cancelPopupButton: {
    marginLeft: 24,
  },
});

const BetslipCancelPopup = ({ count, onCancelBets }) => {
  const classes = useStyles();

  return (
    <TableInlinePopup count={count}>
      <Button
        onClick={onCancelBets}
        disableRipple
        className={classes.cancelPopupButton}
      >
        Cancel Bets
      </Button>
    </TableInlinePopup>
  );
};

export default BetslipCancelPopup;
