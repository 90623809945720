import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import FormField from '../../../../../components/Form/FormField';
import NumberField from '../../../../../components/Inputs/NumberField';
import TextField from '../../../../../components/Inputs/TextField';
import Button from '../../../../../components/Button/Button';

const useStyles = makeStyles({
  createSpecifierForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
  },
  createSpecifierInput: {
    '&:not(:last-of-type)': {
      marginBottom: 8,
    },
  },
  createSpecifierFormButtons: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 24,
    justifyContent: 'flex-end',
  },
  formButton: {
    minWidth: 'unset',
    marginLeft: 18,
  },
});

const CreateSpecifierForm = ({
  marketId,
  specifiers,
  isFreeSpecifier,
  form,
  onCancel,
  onSubmit,
}) => {
  const classes = useStyles();
  const { register, getValues, setValue, watch } = form;
  const createSpecifierValues = watch('createSpecifierValues');

  const fieldName = `createSpecifierValues.${marketId}`;

  const isAddButtonDisabled =
    !createSpecifierValues[marketId] ||
    Object.values(createSpecifierValues[marketId]).some(
      value => !value || (typeof value === 'string' && !value.trim())
    );

  return (
    <div className={classes.createSpecifierForm}>
      {isFreeSpecifier ? (
        <FormField name={`${fieldName}.freeSpecifier`}>
          <TextField label={`Specifier name`} />
        </FormField>
      ) : (
        specifiers.length &&
        specifiers.map(specifier =>
          specifier === 'player' ? (
            <FormField name={`${fieldName}.${specifier}`}>
              <TextField
                key={specifier}
                label={`${specifier} value`}
                className={classes.createSpecifierInput}
              />
            </FormField>
          ) : (
            <FormField name={`${fieldName}.${specifier}`}>
              <NumberField
                key={specifier}
                label={`${specifier} value`}
                step={0.5}
                {...(specifier === 'total'
                  ? {
                      min: 0,
                    }
                  : {})}
                useStringValue
                className={classes.createSpecifierInput}
              />
            </FormField>
          )
        )
      )}
      <div className={classes.createSpecifierFormButtons}>
        <Button
          color={'lightBlue'}
          onClick={onCancel}
          className={classes.formButton}
        >
          Cancel
        </Button>
        <Button
          color={'primary'}
          onClick={() => onSubmit()}
          className={classes.formButton}
          disabled={isAddButtonDisabled}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default CreateSpecifierForm;
