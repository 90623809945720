import { useLazyQuery } from '@apollo/client';
import getMatchCreateModel from '../../gql/EventsGroups/queries/getMatchCreateModel';

const useMatchCreateModel = options => {
  const [fetch, { data, loading, error }] = useLazyQuery(
    getMatchCreateModel,
    options
  );

  const getCreateModel = variables => {
    fetch({ variables });
    return { data, loading, error };
  };

  let model;
  if (data) {
    model = data.getMatchCreateModel;
  }

  return { getCreateModel, model, loading, error };
};

export default useMatchCreateModel;
