import React from 'react';

const Logo = ({ className }) => {
  return (
    <svg
      width="314"
      height="34"
      viewBox="0 0 314 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L46.9057 4.03991e-05C53.7107 4.03991e-05 58.8144 3.11269 58.8144 8.85913C58.8144 12.3344 56.3093 15.417 52.9811 16.1279V16.8551C58.0821 17.4486 60.2721 20.761 60.2721 24.6619C60.2721 30.8872 56.3836 33.9998 45.9331 33.9998L0 33.9998V0ZM32.5669 9.09855H22.8455V12.9295H32.5669C33.6407 12.9295 34.5111 12.0719 34.5111 11.014C34.5111 9.95614 33.6407 9.09855 32.5669 9.09855ZM33.5385 20.5915H22.845V24.4225H33.5385C34.6123 24.4225 35.4827 23.5649 35.4827 22.507C35.4827 21.4491 34.6123 20.5915 33.5385 20.5915Z"
        fill="#1581FF"
      />
      <path
        d="M182.762 4.03991e-05H124.92V15.8027H142.418V33.9998H165.263V15.8027H182.762V4.03991e-05Z"
        fill="#1581FF"
      />
      <path
        d="M314 4.03991e-05H286.709L281.676 8.47617L276.644 4.03991e-05H249.353L270.254 26.8168V33.9998H293.099V26.8168L314 4.03991e-05Z"
        fill="#1581FF"
      />
      <path
        d="M88.9506 24.4224H119.087V33.9998H66.1054V3.93191e-05H119.087V9.09857H88.9506V12.9295H119.087V20.5914H88.9506V24.4224Z"
        fill="#1581FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M235.5 4.03991e-05C242.305 4.03991e-05 247.408 3.11269 247.408 8.85913C247.408 12.3344 244.903 15.4171 241.575 16.1279V16.8551C246.676 17.4486 248.866 20.761 248.866 24.6619C248.866 30.8872 244.978 33.9998 234.527 33.9998L188.594 34V0.000238547L235.5 4.03991e-05ZM217.395 5.93875C217.525 5.76159 217.765 5.69734 217.97 5.78638C218.174 5.87541 218.287 6.09204 218.238 6.30499L216.786 14.0052L223.606 14.005C223.782 14.005 223.944 14.1005 224.025 14.2547C224.106 14.408 224.092 14.5925 223.99 14.7311L214.722 27.5817C214.594 27.7579 214.35 27.8249 214.147 27.7341C213.942 27.645 213.83 27.4275 213.879 27.2145L215.277 19.5563L208.509 19.5124C208.332 19.5124 208.169 19.4151 208.089 19.2637C208.008 19.1095 208.023 18.9259 208.125 18.7864L217.395 5.93875Z"
        fill="#1581FF"
      />
    </svg>
  );
};

export default Logo;
