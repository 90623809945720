import { gql } from '@apollo/client';

const getOperatorEventDetails = gql`
  query getEventDetails($eventId: EventId!) {
    data: getEventDetails(eventId: $eventId) {
      id
      event
      category {
        id
        name
      }
      tournament {
        id
        name
      }
      sport {
        id
        name
      }
      ggr
      avgBet
      maxBet
      players
      bets
      turnover
      turnoverOpen
      lastBetDate
      lastBetDateBet
      firstBetDate
      firstBetDateBet
      maxGgrEur
      minGgrEur
      freebetBets
      freebetPlayers
      freebetTurnover
      freebetGgr
    }
  }
`;

export default getOperatorEventDetails;
