import { Autocomplete, Stack } from '@mui/material';
import TextField from '../../../../components/Inputs/TextField';
import React, { useState, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import TranslationsList from '../TranslationsList';
import {
  useCompetitors,
  useLangsAPI,
} from '../../../../hooks/event-management';
import useDebounced from '../../../../hooks/useDebounced';
import {
  mapDataToAutocompleteValue,
  mapLangsToTranslations,
  sortLanguages,
  isCustomOption,
} from '../../utils';
import ErrorWidget from '../../../../components/ErrorWidget';
import getLanguageInfo from '../../../../gql/EventsGroups/queries/getLanguageInfo';

const AwayTeamAutocomplete = ({ sportId, languages }) => {
  const { setValue, watch } = useFormContext();

  const awayTeam = watch('awayTeam');

  const { getCompetitorLangs, error } = useLangsAPI();

  const {
    competitors,
    refetch: refetchCompetitors,
    error: competitorsError,
  } = useCompetitors({
    variables: {
      query: '',
      sportIds: [sportId],
      limit: 15,
    },
  });

  const { data: { getLanguageInfo: languagesData } = {} } =
    useQuery(getLanguageInfo);

  const [inputValue, setInputValue] = useState('');
  const [isExpandedLanguages, setExpandedLanguages] = useState(false);
  const debouncedCompetitorSearch = useDebounced(setInputValue, 500);

  useEffect(() => {
    if (inputValue) {
      refetchCompetitors({ query: inputValue, sportIds: [sportId] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  const value = useMemo(() => mapDataToAutocompleteValue(awayTeam), [awayTeam]);

  const handleApplyCustomValue = async event => {
    const value = event.target.value;
    const primaryTranslation = awayTeam?.langs
      ? awayTeam.langs.find(l => l.language === 'English').translation
      : null;
    const existingOption = competitors.find(({ name }) => {
      const segments = name.split('/');
      const label = segments[segments.length - 1].trim();
      return label === value;
    });

    if (value) {
      if (value !== primaryTranslation) {
        if (existingOption) {
          const { data } = await getCompetitorLangs({
            sportId,
            competitorId: newValue?.value,
          });
          setValue('awayTeam', {
            ...data.getCompetitorLangs,
            langs: mapLangsToTranslations(
              sortLanguages(data.getCompetitorLangs.langs),
              languagesData
            ),
          });
        } else {
          setValue('awayTeam', {
            id: null,
            countryCode: '',
            langs: [
              {
                language: 'English',
                translation: value,
              },
            ],
          });
        }
      }
    } else {
      setValue('awayTeam', null);
    }
  };

  if (error || competitorsError) {
    return <ErrorWidget />;
  }

  return (
    <Stack gap={'15px'}>
      <Stack gap={'4px'} direction={'row'}>
        <Stack gap={'2px'} sx={{ width: '100%' }}>
          <Autocomplete
            value={value}
            freeSolo
            filterOptions={options => options}
            getOptionLabel={option => {
              if (option.inputValue) {
                return option.inputValue;
              }
              return option.label;
            }}
            onChange={async (event, newValue, reason) => {
              if (reason === 'selectOption') {
                const { data } = await getCompetitorLangs({
                  sportId,
                  competitorId: newValue?.value,
                });
                setValue('awayTeam', {
                  ...data.getCategoryLangs,
                  langs: mapLangsToTranslations(
                    sortLanguages(data.getCompetitorLangs.langs),
                    languagesData
                  ),
                  id: newValue?.value,
                });
              }
            }}
            onBlur={async event => {
              handleApplyCustomValue(event);
            }}
            options={competitors.map(c => ({ label: c.name, value: c.id }))}
            renderOption={(props, option) => <li {...props}>{option.label}</li>}
            sx={{ width: '100%' }}
            renderInput={params => (
              <TextField
                shrinkable
                label={'Away Team'}
                onKeyDown={async event => {
                  if (event.key === 'Enter' && event.target.value) {
                    handleApplyCustomValue(event);
                  }
                }}
                helperText={
                  isCustomOption(awayTeam) ? 'New away team will be added' : ''
                }
                {...params}
              />
            )}
            onInputChange={(_, newInputValue) => {
              debouncedCompetitorSearch(newInputValue);
            }}
          />
        </Stack>
        <TextField
          disabled={!awayTeam}
          value={awayTeam ? awayTeam.countryCode : ''}
          onChange={e =>
            setValue('awayTeam.countryCode', e.target.value.toUpperCase())
          }
          sx={{ width: '108px' }}
        />
      </Stack>
      {awayTeam?.langs && (
        <TranslationsList
          name={'awayTeam'}
          langs={awayTeam.langs}
          languages={languages}
          isExpanded={isExpandedLanguages}
          setExpanded={setExpandedLanguages}
          onChange={langs => {
            setValue('awayTeam', {
              id: awayTeam?.id,
              countryCode: awayTeam?.countryCode,
              langs: langs,
            });
          }}
        />
      )}
    </Stack>
  );
};

export default AwayTeamAutocomplete;
