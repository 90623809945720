import { gql } from '@apollo/client';
import eventModelFragment from '../fragments/eventModelFragment';

const CREATE_EVENT = gql`
  ${eventModelFragment}
  mutation createEvent($fixture: FixtureInput!, $status: CreateEventStatus!) {
    createEvent(fixture: $fixture, status: $status) {
      ...EventModelFragment
    }
  }
`;

export default CREATE_EVENT;
