import { useParams } from 'react-router-dom';
import BetHistory from '../../../../BetHistory/BetHistory';

const EventBetHistoryTab = () => {
  const { eventId } = useParams();

  return <BetHistory eventId={eventId} />;
};

export default EventBetHistoryTab;
