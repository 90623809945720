import { Button, Stack, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';

const ErrorWidget = () => {
  const navigate = useNavigate();

  return (
    <Stack
      gap={'50px'}
      justifyContent={'center'}
      alignItems={'center'}
      height={'100%'}
      minHeight={'700px'}
    >
      <svg
        width="183"
        height="86"
        viewBox="0 0 183 86"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M151.903 62.9146C151.106 67.0565 153.873 71.0564 157.992 71.8496C162.111 72.6429 166.095 69.8547 166.892 65.7128C167.689 61.571 164.922 57.571 160.803 56.7778C156.684 55.9845 152.7 58.7727 151.903 62.9146ZM0 56.5247C4.8754 70.1288 16.6258 80.8169 31.8387 83.6104C57.8544 88.4329 66.8728 64.7106 84.2454 67.8648L158.136 85.4848C169.399 88.1804 180.503 79.9688 182.643 68.5403C184.76 57.0446 177.237 45.7499 165.832 43.9364L90.4737 34.1016C73.168 30.9234 73.1618 5.51826 47.1461 0.695755C31.9332 -2.09782 17.1959 3.70989 7.75944 14.7271C20.069 16.9725 29.0031 18.6121 41.3363 20.9248C47.5482 22.1027 47.2292 21.8388 49.3374 27.8137C50.6876 31.6402 52.0615 35.534 53.4117 39.3606C55.5199 45.3354 55.6019 44.9279 51.5219 49.792C48.8674 52.9357 46.256 55.9883 43.6014 59.132C39.5214 63.9961 39.8556 63.8764 33.6437 62.6985C21.1768 60.4338 12.3095 58.7702 0 56.5247Z"
          fill="#E1E6EC"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M45.0465 25.6784C44.7179 24.7758 43.9386 24.114 42.9966 23.9375L24.5989 20.4891C23.6569 20.3125 22.6918 20.6474 22.0601 21.37L9.96311 35.2078C9.30981 35.9552 9.11497 37.0002 9.45488 37.9337L15.749 55.2187C16.0777 56.1213 16.8569 56.783 17.7989 56.9596L36.1967 60.408C37.1387 60.5845 38.1037 60.2497 38.7354 59.5271L50.8324 45.6892C51.4857 44.9419 51.6806 43.8969 51.3407 42.9634L45.0465 25.6784ZM28.8524 48.7372C33.4181 49.593 37.8113 46.5757 38.6648 41.998C39.5183 37.4202 36.509 33.0155 31.9433 32.1597C27.3775 31.3039 22.9844 34.3212 22.1308 38.8989C21.2773 43.4767 24.2866 47.8814 28.8524 48.7372Z"
          fill="#BEC8D5"
        />
      </svg>
      <Stack gap="24px" justifyContent={'center'} alignItems={'center'}>
        <Typography
          fontSize={'2.125rem'}
          fontWeight={500}
          lineHeight={'1.235'}
          letterSpacing={'0.2px'}
          color={'#031224'}
          fontFamily={'Inter'}
        >
          Something went wrong
        </Typography>
        <Button
          onClick={() => navigate(-1)}
          variant={'contained'}
          sx={{
            padding: '11px 24px',
          }}
        >
          Back
        </Button>
      </Stack>
    </Stack>
  );
};

export default ErrorWidget;
