import { gql } from '@apollo/client';

const eventModelFragment = gql`
  fragment EventModelFragment on EventModel {
    id
    fixture {
      sport {
        id
        name
      }
      category {
        id
        name
      }
      tournament {
        id
        name
      }
      scheduled
      competitors {
        id
        name
      }
    }
    state {
      status
    }
    markets {
      id
      name
      enabled
      outcomes
      main_specifier
    }
    stats {
      score {
        home
        away
      }
    }
    version
    blocked_markets
  }
`;

export default eventModelFragment;
